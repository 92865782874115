import { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import { detailsTabAtom } from 'atoms'
import { Flex, Text, RichText, SideTabs, Loading } from 'components'
import { useContentful } from 'utils'
import DetailSelection from './DetailSelection'
import useDetailsComplete from './useDetailsComplete'
import useGetOptions from './useGetOptions'

export default ({ text: { details: { greeting, description } = {} } }) => {
  const { detailOptions } = useContentful('detailsPage')
  const paymentOptions = useGetOptions(detailOptions)
  const [selectedTab, setSelectedTab] = useRecoilState(detailsTabAtom)
  const [options, setOptions] = useState([])

  useDetailsComplete(detailOptions)

  const populateDetails = () => {
    const categories = detailOptions.map(({ fields: { category } }) => category)

    const sanitizedDetails = detailOptions.map(
      ({ fields: { category } }, i) => ({
        title: category,
        content: (
          <DetailSelection
            categories={categories}
            category={category}
            {...paymentOptions?.[i]}
          />
        ),
      }),
    )

    setOptions(sanitizedDetails)
  }

  useEffect(() => {
    paymentOptions && detailOptions && populateDetails()
    //eslint-disable-next-line
  }, [detailOptions, paymentOptions])

  return (
    <Flex column>
      <Flex column center marginBottom="40px" phoneMarginBottom="20px">
        <Text header margin="0">
          {greeting}
        </Text>
        <RichText paddingTop="25px">{description}</RichText>
      </Flex>
      <Loading loading={!detailOptions} full height="600px" phoneFull phoneTall>
        <SideTabs
          full
          maxWidth="850px"
          height="600px"
          phoneFull
          phoneTall
          phonePadding="10px"
          selected={[selectedTab, setSelectedTab]}
          tabs={options}
        />
      </Loading>
    </Flex>
  )
}
