import * as S from './Input.css'
import { Flex, Text, Icon } from 'components'

export default ({
  value: [value, setValue],
  label,
  placeholder,
  valid,
  ...props
}) => (
  <Flex column {...props}>
    {label && (
      <Text emphasis noSelect marginBottom="10px">
        {label}
        {valid && <Icon type="checkmark" fill="brand" height="10px" />}
      </Text>
    )}
    <S.Input
      value={value}
      onChange={({ target }) => setValue(target.value)}
      placeholder={placeholder}
    />
  </Flex>
)
