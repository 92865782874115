import React from 'react'
import ReactDOM from 'react-dom/client'
import { Recoil, ReCaptcha, Theme, Styles, Routes } from './providers'

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <Recoil>
      <Theme>
        <ReCaptcha>
          <Styles />
          <Routes />
        </ReCaptcha>
      </Theme>
    </Recoil>
  </React.StrictMode>,
)
