import { Flex, Text, Button, Link, Image, RichText, Loading } from 'components'
import { useContentful } from 'utils'

export default () => {
  const { tagline, socialMediaLinks } = useContentful('homePage')

  return (
    <Flex minHeight="100vh" center column>
      <Image
        src="logo"
        height="300px"
        width="650px"
        backgroundSize="cover"
        marginBottom="50px"
      />
      <Loading
        loading={!tagline}
        marginBottom="150px"
        width="400px"
        height="70px"
        phoneFull
        phonePadding="20px"
      >
        <RichText
          size="20px"
          marginBottom="150px"
          width="350px"
          align="center"
          phoneFull
          phoneSize="16px"
          phonePadding="0 20px"
          phoneMarginBottom="75px"
        >
          {tagline}
        </RichText>
      </Loading>
      <Flex
        gap="40px"
        phoneFull
        phoneColumn
        phoneGap="20px"
        phonePadding="0 20px"
      >
        <Link href="/commission">
          <Button glow width="280px" phoneWidth="100%">
            Get a commission
          </Button>
        </Link>
        <Link href="/portfolio">
          <Button glow width="280px" phoneWidth="100%">
            See my work
          </Button>
        </Link>
      </Flex>
      <Loading
        loading={!socialMediaLinks}
        height="15px"
        width="400px"
        mobileWidth="200px"
        margin="40px 15px 15px 15px"
      >
        <Flex
          margin="40px 15px 15px 15px"
          opacity=".50"
          phoneColumn
          phoneAlign="center"
        >
          {socialMediaLinks?.map(({ fields: { link, name } }, i) => (
            <Link href={link} key={name}>
              <Text emphasis marginRight="10px">
                {name} {i !== socialMediaLinks.length - 1 && '|'}
              </Text>
            </Link>
          ))}
        </Flex>
      </Loading>
    </Flex>
  )
}
