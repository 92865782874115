import { css } from 'styled-components'
import { media } from 'styles'

export default ({
  flexWrap,
  flexShrink,
  flexGrow,
  flexDirection,
  column,
}) => css`
  display: flex;
  flex-wrap: ${flexWrap};
  flex-grow: ${flexGrow};
  flex-shrink: ${flexShrink};
  flex-direction: ${flexDirection || (column && 'column')};
  ${alignment}

  ${tablet}
  ${phone}
`

const tablet = ({
  tabletFlexWrap,
  tabletFlexShrink,
  tabletFlexGrow,
  tabletFlexDirection,
  tabletColumn,
}) => css`
  ${media.tablet`
    display: flex;
    flex-wrap: ${tabletFlexWrap};
    flex-grow: ${tabletFlexGrow};
    flex-shrink: ${tabletFlexShrink};
    flex-direction: ${tabletFlexDirection || (tabletColumn && 'column')};
    ${tabletAlignment}
  `}
`

const phone = ({
  phoneFlexWrap,
  phoneFlexShrink,
  phoneFlexGrow,
  phoneFlexDirection,
  phoneColumn,
}) => css`
  ${media.phone`
    display: flex;
    flex-wrap: ${phoneFlexWrap};
    flex-grow: ${phoneFlexGrow};
    flex-shrink: ${phoneFlexShrink};
    flex-direction: ${phoneFlexDirection || (phoneColumn && 'column')};
    ${phoneAlignment}
  `}
`

const alignment = ({ center, justify, align }) => css`
  justify-content: ${center ? 'center' : justify};
  align-items: ${center ? 'center' : align};
`

const tabletAlignment = ({ tabletCenter, tabletJustify, tabletAlign }) => css`
  justify-content: ${tabletCenter ? 'center' : tabletJustify};
  align-items: ${tabletCenter ? 'center' : tabletAlign};
`

const phoneAlignment = ({ phoneCenter, phoneJustify, phoneAlign }) => css`
  justify-content: ${phoneCenter ? 'center' : phoneJustify};
  align-items: ${phoneCenter ? 'center' : phoneAlign};
`
