import { atom } from 'recoil'

export const referencesAtom = atom({
  key: 'references',
  default: [],
})

export const referenceLoadingAtom = atom({
  key: 'referenceLoading',
  default: false,
})

export const uploadPreferenceAtom = atom({
  key: 'uploadPreference',
  default: 'Upload images',
})

export const referenceImageAtom = atom({
  key: 'referenceImage',
  default: {},
})

export const referenceLinkAtom = atom({
  key: 'referenceLink',
  default: '',
})

export const hasReferencesAtom = atom({
  key: 'hasReferences',
  default: true,
})

export const referenceDescriptionAtom = atom({
  key: 'referenceDescription',
  default: '',
})
