import styled from 'styled-components'
import Flex from 'components/Global/Flex/Flex'
import { colors as c } from 'utils'

export const Loading = styled(Flex)`
  position: relative;
  overflow: hidden;
  border-radius: 10px;

  @keyframes loading {
    100% {
      transform: translateX(100%);
    }
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
    transform: translateX(-100px);
    background: linear-gradient(
      90deg,
      transparent,
      ${c('white', '25')},
      transparent
    );
    animation: loading 0.8s infinite;
  }
`
