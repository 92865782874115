import { atom } from 'recoil'

export const paypalEmailAtom = atom({
  key: 'paypalEmail',
  default: '',
})

export const useContactEmailAtom = atom({
  key: 'useContactEmail',
  default: false,
})
