import { useRecoilState, useSetRecoilState } from 'recoil'
import { detailsAtom, detailsTabAtom } from 'atoms'
import { Flex, Text, RichText, ContentfulImage } from 'components'
import findIndex from 'lodash/findIndex'

export default ({ options, description, categories, category }) => {
  const [details, setDetails] = useRecoilState(detailsAtom)
  const setDetailsTab = useSetRecoilState(detailsTabAtom)

  const nextTab = () => {
    const currentTab = findIndex(
      categories,
      (currentCategory) => currentCategory === category,
    )

    const isLast = categories.length - 1 === currentTab
    const alreadyHasSelection = details?.[category]
    const proceed = () => setDetailsTab(categories[currentTab + 1])
    !isLast && !alreadyHasSelection && setTimeout(proceed, 100)
  }

  const selectOption = (option) => {
    const _selectedOptions = { ...details }
    delete option.title
    _selectedOptions[category] = option

    setDetails(_selectedOptions)
    nextTab()
  }

  return (
    <Flex column margin="0 15px" phonePadding="10px 0">
      <RichText marginBottom="20px">{description}</RichText>
      <Flex
        flexWrap="wrap"
        display="flex-grid"
        gap="40px"
        phoneFull
        phoneColumn
      >
        {options?.map(({ fields: option = {} }) => {
          const { type, thumbnail, price } = option
          const selected = details?.[category]?.type === type

          return (
            <ContentfulImage
              framed
              clickable
              width="calc(50% - 25px)"
              height="200px"
              justify="space-between"
              padding="25px"
              align="flex-end"
              outline={selected ? '4px solid' : '0px solid'}
              outlineColor="brand"
              phoneFull
              onClick={() => selectOption(option)}
              tabletFull
              tabletHeight="250px"
              src={thumbnail}
              key={type}
            >
              <Flex
                full
                noSelect
                blur="16px"
                padding="5px 15px"
                borderRadius="50px"
                background="brand1"
                backgroundOpacity="25"
                justify="space-between"
                align="center"
              >
                <Text emphasis color="darkGray">
                  {type}:
                </Text>
                <Text emphasis color="darkGray" weight="700" marginLeft="15px">
                  ${price}
                </Text>
              </Flex>
            </ContentfulImage>
          )
        })}
      </Flex>
    </Flex>
  )
}
