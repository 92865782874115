import styled from 'styled-components'
import { media } from 'styles'

export const Grid = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  overflow: auto;
  gap: 25px;
  padding: 25px;

  ${media.desktopPlus`
    grid-template-columns: repeat(1, 1fr);
  `}

  ${media.laptop`
    grid-template-columns: repeat(1, 1fr);
  `}

  ${media.phone`
    grid-template-columns: repeat(1, 1fr);
    gap: 25px;
    padding: 10px;
  `}
`
