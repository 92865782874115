import { css } from 'styled-components'

export default (allProps, types) => {
  let totalCSS = ``
  const { children, theme, ...props } = allProps
  const propArray = Object.entries(props)
  propArray.map(
    ([prop, value]) =>
      value &&
      types?.[prop] &&
      (totalCSS = css`
        ${totalCSS}${types?.[prop]}
      `),
  )

  const hasCSS = totalCSS !== ``
  const defaultStyle = types?.default || css``
  return hasCSS ? totalCSS : defaultStyle
}
