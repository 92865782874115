import { useRecoilState } from 'recoil'
import { referencesAtom } from 'atoms'
import { Flex } from 'components'
import UploadedImage from './UploadedImage/UploadedImage'
import * as S from './ReferenceImages.css'

export default () => {
  const [references, setReferences] = useRecoilState(referencesAtom)

  return (
    <Flex
      overflowY="auto"
      maxHeight="600px"
      phoneOverflowY="visible"
      phoneMaxHeight="100%"
      full
    >
      <S.Grid>
        {references?.map((reference, i) => (
          <UploadedImage
            references={[references, setReferences]}
            index={i}
            key={reference?.id}
            {...reference}
          />
        ))}
      </S.Grid>
    </Flex>
  )
}
