import * as S from './Container.css'

export default ({ ...props }) => (
  <S.Container
    padding="25px"
    borderRadius="25px"
    background="white"
    backgroundOpacity="5"
    phonePadding="15px"
    {...props}
  />
)
