import { useRecoilState } from 'recoil'
import {
  nameAtom,
  emailAtom,
  privateCommissionAtom,
  prefersCreditAtom,
  userPageLinkAtom,
  preferredCommunicationAtom,
  telegramUsernameAtom,
  discordUsernameAtom,
} from 'atoms'
import { Flex, Text, RichText, Input, Checkbox, RadioButtons } from 'components'
import { validate } from 'utils'
import useContactComplete from './useContactComplete'

export default ({ text: { contact: { greeting, description } = {} } }) => {
  useContactComplete()

  const [name, setName] = useRecoilState(nameAtom)
  const [email, setEmail] = useRecoilState(emailAtom)
  const [privateCommission, setPrivateCommission] = useRecoilState(
    privateCommissionAtom,
  )
  const [prefersCredit, setPrefersCredit] = useRecoilState(prefersCreditAtom)
  const [userPageLink, setUserPageLink] = useRecoilState(userPageLinkAtom)
  const [preferredCommunication, setPreferredCommunication] = useRecoilState(
    preferredCommunicationAtom,
  )
  const [telegramUsername, setTelegramUsername] =
    useRecoilState(telegramUsernameAtom)
  const [discordUsername, setDiscordUsername] =
    useRecoilState(discordUsernameAtom)

  return (
    <Flex column align="center">
      <Flex marginBottom="30px" column center>
        <Text header margin="0">
          {greeting}
        </Text>
        <RichText paddingTop="25px">{description}</RichText>
      </Flex>
      <Flex gap="100px" phoneColumn phoneGap="25px" tabletColumn>
        <Flex column width="350px" phoneFull>
          <Input
            label="Name"
            valid={name}
            value={[name, setName]}
            marginBottom="25px"
          />
          <Input
            label="Email"
            valid={validate.isEmail(email)}
            value={[email, setEmail]}
            marginBottom="25px"
          />
          <Checkbox
            checked={privateCommission}
            onClick={() => setPrivateCommission(!privateCommission)}
            marginBottom="25px"
          >
            I would like this to be a private commisson
          </Checkbox>
          <Checkbox
            checked={prefersCredit}
            onClick={() => setPrefersCredit(!prefersCredit)}
            marginBottom="25px"
          >
            I would like to be credited as the commissioner
          </Checkbox>
          <Input
            label="Link to userpage"
            valid={validate.isUrl(userPageLink)}
            value={[userPageLink, setUserPageLink]}
            disabled={!prefersCredit}
          />
        </Flex>
        <Flex column width="350px" phoneFull>
          <Text marginBottom="10px">
            What messaging service would you prefer to communicate with?
          </Text>
          <RadioButtons
            selected={[preferredCommunication, setPreferredCommunication]}
            options={[
              { text: 'Telegram', value: 'Telegram' },
              { text: 'Discord', value: 'Discord' },
              { text: 'Email', value: 'Email' },
            ]}
            marginBottom="25px"
          />
          {preferredCommunication === 'Telegram' && (
            <Input
              valid={telegramUsername}
              label="Telegram username"
              value={[telegramUsername, setTelegramUsername]}
            />
          )}
          {preferredCommunication === 'Discord' && (
            <Input
              valid={validate.isDiscordUsername(discordUsername)}
              label="Discord username"
              value={[discordUsername, setDiscordUsername]}
            />
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}
