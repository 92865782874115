import { Flex, Text, Link, Button, Container, Image } from 'components'
import { useSetRecoilState } from 'recoil'
import { stepAtom } from 'atoms'

export default ({ details }) => {
  const setStep = useSetRecoilState(stepAtom)

  return (
    <Container column marginBottom="25px" width="400px" phoneFull>
      <Text emphasis marginBottom="25px" phoneAlign="center">
        Details
      </Text>
      <Flex column gap="25px">
        {details?.map(({ type, price, thumbnail }) => (
          <Image
            full
            framed
            center
            height="200px"
            padding="25px"
            src={thumbnail}
            key={type}
          >
            <Flex
              background="white"
              backgroundOpacity="25"
              borderRadius="50px"
              justify="space-between"
              padding="10px 25px"
              blur="32px"
              align="center"
              phoneColumn
            >
              <Text
                emphasis
                marginRight="5px"
                color="brand7"
                phonePaddingBottom="5px"
                phoneAlign="center"
              >
                {type}:
              </Text>
              <Text emphasis weight="700" color="brand7" phoneSize="14px">
                ${price.toFixed(2)}
              </Text>
            </Flex>
          </Image>
        ))}
      </Flex>
      <Flex center marginTop="25px">
        <Link href="/commission/customize/" onClick={() => setStep('Details')}>
          <Button>Edit</Button>
        </Link>
      </Flex>
    </Container>
  )
}
