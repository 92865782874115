import styled from 'styled-components'
import Flex from 'components/Global/Flex/Flex'
import { media } from 'styles'

export const Container = styled(Flex)`
  flex-direction: row;

  ${media.tablet`
    flex-direction: column;
  `}

  ${media.laptop`
    flex-direction: column;
  `}

  ${media.desktop`
    flex-direction: column;
  `}
`
