import { Flex, Text, Link, Button, Container } from 'components'
import { useSetRecoilState } from 'recoil'
import { stepAtom } from 'atoms'
import { truncate } from 'utils'

export default ({
  name,
  email,
  privateCommission,
  prefersCredit,
  userPageLink,
  preferredCommunication,
  username,
}) => {
  const setStep = useSetRecoilState(stepAtom)

  return (
    <Container column marginBottom="25px" gap="10px" width="400px" phoneFull>
      <Text emphasis marginBottom="15px" phoneAlign="center">
        Contact
      </Text>
      <Flex>
        <Text weight="600" marginRight="5px">
          • Name:
        </Text>
        <Text>{name}</Text>
      </Flex>
      <Flex>
        <Text weight="600" marginRight="5px">
          • Email:
        </Text>
        <Link href={`mailto:${email}`}>
          <Text decoration="underline">{email}</Text>
        </Link>
      </Flex>
      {prefersCredit && (
        <Flex column>
          <Text weight="600" marginRight="5px">
            • Link to commissioner's page to be credited:
          </Text>
          <Link href={userPageLink}>
            <Text decoration="underline" wordBreak="break-all">
              {truncate(userPageLink, 35)}
            </Text>
          </Link>
        </Flex>
      )}
      <Flex>
        <Text>
          {privateCommission
            ? '• Commissioner has specified the commission to be private and not shared on social media.'
            : "• The commission will be uploaded on Proteon's social media."}
        </Text>
      </Flex>
      <Flex>
        <Text>
          • The commissioner would prefer to be contacted through{' '}
          {preferredCommunication.toLowerCase()}
        </Text>
      </Flex>
      {username && (
        <Flex>
          <Text weight="600" marginRight="5px">
            • username:{' '}
          </Text>
          <Text>{username}</Text>
        </Flex>
      )}
      <Flex center marginTop="25px">
        <Link href="/commission/customize/" onClick={() => setStep('Contact')}>
          <Button>Edit</Button>
        </Link>
      </Flex>
    </Container>
  )
}
