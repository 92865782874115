import * as S from './Tabs.css'
import { Flex, Text } from 'components'
import React from 'react'

export default ({
  tabs,
  selected: [selected, setSelected],
  content,
  ...props
}) => {
  return (
    <Flex column center {...props}>
      <S.Tabs>
        {tabs?.map(({ title }) => (
          <S.Tab
            center
            clickable
            active={title === selected}
            onClick={() => setSelected(title)}
            key={title}
          >
            <Text
              emphasis
              noSelect
              size="12px"
              align="center"
              phoneSize="10px"
              phoneWeight="700"
            >
              {title}
            </Text>
          </S.Tab>
        ))}
      </S.Tabs>
      <Flex full>
        {tabs?.map(
          ({ title, content }) =>
            title === selected && (
              <React.Fragment key={title}>{content}</React.Fragment>
            ),
        )}
      </Flex>
    </Flex>
  )
}
