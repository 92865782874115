import { css } from 'styled-components'
import { colors as c } from 'utils'
import { media, disabled } from 'styles'

export default ({
  background,
  backgroundOpacity,
  borderRadius,
  opacity,
  overflow,
  overflowX,
  overflowY,
  pointerEvents,
  transition,
  shadow,
  blur,
  clickable,
  noSelect,
}) => css`
  background: ${background && c(background, backgroundOpacity)};
  border-radius: ${borderRadius};
  opacity: ${opacity};
  overflow: ${overflow};
  overflow-x: ${overflowX};
  overflow-y: ${overflowY};
  pointer-events: ${pointerEvents};
  transition: ${transition};
  box-shadow: ${shadow};
  backdrop-filter: blur(${blur});
  user-select: ${noSelect && `none`};
  cursor: ${clickable && `pointer`};
  ${disabled}

  ${tablet}
  ${phone}
`

const tablet = ({
  tabletBackground,
  backgroundOpacity,
  tabletOverflow,
  tabletOverflowX,
  tabletOverflowY,
  tabletBorderRadius,
}) => css`
  ${media.tablet`
    background: ${tabletBackground && c(tabletBackground, backgroundOpacity)};
    overflow: ${tabletOverflow};
    overflow-x: ${tabletOverflowX};
    overflow-y: ${tabletOverflowY};
    border-radius: ${tabletBorderRadius};
  `}
`

const phone = ({
  phoneBackground,
  backgroundOpacity,
  phoneOverflow,
  phoneOverflowX,
  phoneOverflowY,
  phoneBorderRadius,
}) => css`
  ${media.phone`
    background: ${phoneBackground && c(phoneBackground, backgroundOpacity)};
    overflow: ${phoneOverflow};
    overflow-x: ${phoneOverflowX};
    overflow-y: ${phoneOverflowY};
    border-radius: ${phoneBorderRadius};
  `}
`
