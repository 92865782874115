import { css } from 'styled-components'
import { colors as c } from 'utils'

export default ({
  border,
  borderTop,
  borderRight,
  borderBottom,
  borderLeft,
  borderColor,
  borderOpacity,
  borderStyle,
  outline,
  outlineColor,
  outlineOpacity,
  outlineWidth,
  outlineStyle,
}) => css`
  border: ${border};
  border-top: ${borderTop};
  border-right: ${borderRight};
  border-bottom: ${borderBottom};
  border-left: ${borderLeft};
  border-color: ${borderColor && c(borderColor, borderOpacity)}};
  border-style: ${borderStyle};
  outline: ${outline};
  outline-color: ${outlineColor && c(outlineColor, outlineOpacity)}};
  outline-width: ${outlineWidth};
  outline-style: ${outlineStyle};
`
