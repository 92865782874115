import { createClient } from 'contentful'
import { useState, useEffect } from 'react'
const { REACT_APP_CONTENTFUL_SPACE_ID, REACT_APP_CONTENTFUL_ACCESS_TOKEN } =
  process.env

const query = async (category) => {
  const client = createClient({
    space: REACT_APP_CONTENTFUL_SPACE_ID,
    accessToken: REACT_APP_CONTENTFUL_ACCESS_TOKEN,
  })

  try {
    const response = await client.getEntries({
      content_type: 'detailOptions',
      select: 'fields',
      limit: 1000,
      'fields.category': category,
    })

    const sanitizedResponse = response.items?.[0]?.fields
    delete sanitizedResponse.title
    return sanitizedResponse
  } catch {
    return {}
  }
}

const getOptions = async (detailOptions, setOptions) => {
  const categories = detailOptions?.map(({ fields: { category } }) => {
    return query(category)
  })

  const data = await Promise.all(categories)
  setOptions(data)
}

export default (detailOptions) => {
  const [options, setOptions] = useState()

  useEffect(() => {
    detailOptions && getOptions(detailOptions, setOptions)
    // eslint-disable-next-line
  }, [detailOptions])

  return options
}
