import { useRecoilState, useRecoilValue } from 'recoil'
import {
  referencesAtom,
  referenceLoadingAtom,
  uploadPreferenceAtom,
  referenceImageAtom,
  referenceLinkAtom,
  hasReferencesAtom,
  referenceDescriptionAtom,
} from 'atoms'
import {
  Flex,
  Text,
  RichText,
  RadioButtons,
  Button,
  Tabs,
  Container,
  Input,
  Textarea,
} from 'components'
import { v4 as uuidv4 } from 'uuid'
import ImageUploader from './ImageUploader/ImageUploader'
import ReferenceImages from './ReferenceImages/ReferenceImages'
import isEmpty from 'lodash/isEmpty'
import useReferencesComplete from './useReferencesComplete'
import { validate } from 'utils'
import * as S from './References.css'

export default ({
  text: { references: { greeting, description: greetingDescription } = {} },
}) => {
  useReferencesComplete()

  const [hasReferences, setHasReferences] = useRecoilState(hasReferencesAtom)
  const [references, setReferences] = useRecoilState(referencesAtom)
  const [uploadPreference, setUploadPreference] =
    useRecoilState(uploadPreferenceAtom)
  const [referenceImage, setReferenceImage] = useRecoilState(referenceImageAtom)
  const [referenceLink, setReferenceLink] = useRecoilState(referenceLinkAtom)
  const [referenceDescription, setReferenceDescription] = useRecoilState(
    referenceDescriptionAtom,
  )
  const referenceLoading = useRecoilValue(referenceLoadingAtom)

  const type = uploadPreference === 'Upload images' ? 'image' : 'link'
  const maxUploads = references.length >= 10

  const isValidLink = type === 'link' ? validate.isUrl(referenceLink) : true

  const resetUploader = () => {
    setReferenceImage({})
    setReferenceLink('')
    setReferenceDescription('')
  }

  const uploadReference = () => {
    const _references = [...references]

    const newReference = {
      id: uuidv4(),
      type,
      description: referenceDescription,
      url: type === 'image' ? referenceImage?.url : referenceLink,
      s3key: referenceImage?.key || '',
    }

    _references.push(newReference)
    setReferences(_references)
    resetUploader()
  }

  const uploadDisabled =
    type === 'image' ? isEmpty(referenceImage) : !referenceLink

  return (
    <Flex column align="center">
      <Flex column marginBottom="40px" center phoneMarginBottom="20px">
        <Text header margin="0">
          {greeting}
        </Text>
        <RichText paddingTop="25px">{greetingDescription}</RichText>
      </Flex>
      <RadioButtons
        selected={[hasReferences, setHasReferences]}
        options={[
          { text: 'I have referenes', value: true },
          { text: "I don't have referenes", value: false },
        ]}
        marginBottom="40px"
        phoneMarginBottom="20px"
      />
      {hasReferences ? (
        <Flex gap="40px" phoneColumn tabletColumn>
          <Container column width="400px" phoneFull disabled={maxUploads}>
            <Tabs
              selected={[uploadPreference, setUploadPreference]}
              tabs={[
                {
                  title: 'Upload images',
                  content: <ImageUploader />,
                },
                {
                  title: 'Link images',
                  content: (
                    <Flex center full>
                      <Input
                        full
                        margin="25px 0"
                        label="Link to reference"
                        valid={isValidLink}
                        value={[referenceLink, setReferenceLink]}
                      />
                    </Flex>
                  ),
                },
              ]}
            />
            <Textarea
              label="Reference description (optional)"
              value={[referenceDescription, setReferenceDescription]}
              marginBottom="30px"
              height="200px"
            />
            <Flex center>
              <Button
                glow
                disabled={uploadDisabled || referenceLoading || !isValidLink}
                onClick={() => uploadReference()}
              >
                Upload
              </Button>
            </Flex>
          </Container>
          <S.ImagesContainer column tall phoneFull padding="0">
            <Text
              emphasis
              margin="35px 35px 10px 35px"
              phoneMargin="20px 20px 5px 20px"
            >
              {references?.length}/10 images uploaded
            </Text>
            <ReferenceImages />
          </S.ImagesContainer>
        </Flex>
      ) : (
        <Text
          width="400px"
          align="center"
          size="20px"
          phoneSize="16px"
          phoneFull
          phonePadding="10px"
          phoneMarginTop="20px"
          phoneMarginBottom="40px"
        >
          No worries! Please describe your characters best you can on the next
          step.
        </Text>
      )}
    </Flex>
  )
}
