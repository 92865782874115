import { css } from 'styled-components'
import { media } from 'styles'

export default ({ position, top, right, bottom, left, zIndex }) => css`
  position: ${position};
  top: ${top};
  right: ${right};
  bottom: ${bottom};
  left: ${left};
  z-index: ${zIndex};

  ${tablet}
  ${phone}
`

const tablet = ({
  tabletPosition,
  tabletTop,
  tabletRight,
  tabletBottom,
  tabletLeft,
}) => css`
  ${media.tablet`
    position: ${tabletPosition};
    top: ${tabletTop};
    right: ${tabletRight};
    bottom: ${tabletBottom};
    left: ${tabletLeft};
  `}
`

const phone = ({
  phonePosition,
  phoneTop,
  phoneRight,
  phoneBottom,
  phoneLeft,
}) => css`
  ${media.phone`
    position: ${phonePosition};
    top: ${phoneTop};
    right: ${phoneRight};
    bottom: ${phoneBottom};
    left: ${phoneLeft};
  `}
`
