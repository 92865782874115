// this hook is meant for using colors outside of styled components

import { hexOpacity } from 'constants'
import { themeAtom } from 'atoms'
import { themes } from 'constants'
import { useRecoilValue } from 'recoil'

export default () => {
  const theme = useRecoilValue(themeAtom)

  return (color, opacity) => {
    const themeColor = themes?.[theme]?.[color] || color || color
    const solidColor = themeColor
    const transparentColor = themeColor
      ? `${themeColor}${hexOpacity?.[opacity] || 100}`
      : color

    return opacity ? transparentColor : solidColor
  }
}
