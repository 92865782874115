import { useState, useEffect } from 'react'
import { Flex, Text, Button } from 'components'
import { useNavigate } from 'react-router-dom'
import {
  ContactReceipt,
  DescriptionReceipt,
  DetailsReceipt,
  PaymentReceipt,
  ReferencesReceipt,
} from './Steps'
import useMakeOrder from './useMakeOrder'
import useResetCustomizer from './useResetCustomizer'
import { GridLoader } from 'react-spinners'
import { useColors, useReCaptcha } from 'utils'
import { order } from 'api'

export default () => {
  const [loading, setLoading] = useState(false)
  const resetCustomizer = useResetCustomizer()
  const { details, references, description, contact, payment } = useMakeOrder()

  const c = useColors()
  let navigate = useNavigate()
  const reCaptcha = useReCaptcha()

  useEffect(() => {
    !payment?.paypalEmail && navigate('/commission/customize')
    // eslint-disable-next-line
  }, [])

  const makeOrder = async () => {
    setLoading(true)
    await order.makeOrder({
      details,
      references,
      description,
      contact,
      payment,
    })
    resetCustomizer()
    navigate('/commission/complete')
  }

  return (
    <Flex column align="center" padding="40px" phonePadding="10px">
      <Text header marginBottom="15px">
        Receipt
      </Text>
      <Text emphasis marginBottom="50px" width="200px" align="center">
        Does everything look alright?
      </Text>

      <DetailsReceipt details={details} />
      <ReferencesReceipt {...references} />
      <DescriptionReceipt description={description} />
      <ContactReceipt {...contact} />
      <PaymentReceipt {...payment} />

      <Flex marginBottom="40px" width="400px" center>
        <Text emphasis size="20px" marginRight="10px">
          Estimated Total
        </Text>
        <Text emphasis size="20px" weight="700">
          ${payment.total.toFixed(2)}
        </Text>
      </Flex>

      {loading ? (
        <GridLoader color={c('brand')} />
      ) : (
        <Button glow onClick={() => reCaptcha(makeOrder)}>
          Next
        </Button>
      )}
    </Flex>
  )
}
