import styled from 'styled-components'
import Flex from 'components/Global/Flex/Flex'
import { colors as c } from 'utils'

export const Cart = styled(Flex)`
  background: radial-gradient(
    267.11% 100% at 0% 0%,
    ${c('brand2')} 0%,
    ${c('brand')} 100%
  );
  box-shadow: inset 0px -3px 5px ${c('brand1', '60')};
  border-radius: 25px;
`
