import { Flex, Text, Link, Button, Container } from 'components'
import { useSetRecoilState } from 'recoil'
import { stepAtom } from 'atoms'

export default ({ description }) => {
  const setStep = useSetRecoilState(stepAtom)

  return (
    <Container column marginBottom="25px" width="400px" phoneFull>
      <Text emphasis marginBottom="25px" phoneAlign="center">
        Description
      </Text>
      <Text>{description}</Text>
      <Flex center marginTop="25px">
        <Link
          href="/commission/customize/"
          onClick={() => setStep('Description')}
        >
          <Button>Edit</Button>
        </Link>
      </Flex>
    </Container>
  )
}
