import styled from 'styled-components'
import Flex from 'components/Global/Flex/Flex'
import Text from 'components/Global/Text/Text'
import { colors as c } from 'utils'

export const UploadText = styled(Text)`
  pointer-events: none;
`

export const FileDropzone = styled(Flex)`
  &:active {
    border: 5px dashed ${c('brand1')};
  }
  &:active ${UploadText} {
    color: ${c('brand1')};
  }
`

export const HiddenInput = styled.input`
  outline: none;
`
