import { Flex, Text } from 'components'
import * as S from './RadioButtons.css'

export default ({
  selected: [selected, setSelected],
  options,
  glow,
  ...props
}) => {
  return (
    <Flex column {...props}>
      {options?.map(({ text, value }) => (
        <S.RadioButton
          align="center"
          noSelect
          clickable
          padding="10px 0"
          onClick={() => setSelected(value)}
          key={value}
        >
          <S.Orb
            width="30px"
            height="30px"
            minWidth="30px"
            minHeight="30px"
            phoneWidth="25px"
            phoneHeight="25px"
            phoneMinWidth="25px"
            phoneMinHeight="25px"
            glow={glow}
            center
          >
            <S.OrbTick selected={selected === value} />
          </S.Orb>
          <Text emphasis margin="0 15px">
            {text}
          </Text>
        </S.RadioButton>
      ))}
    </Flex>
  )
}
