import { Flex, Text } from 'components'

export default ({ tabs, content, ...props }) => {
  return (
    <Flex column {...props}>
      {tabs?.map(({ title, content }) => (
        <Flex column key={title + 'mobile'}>
          <Text
            emphasis
            noSelect
            phoneSize="14px"
            weight="600"
            whiteSpace="nowrap"
            align="center"
            padding="25px 0"
          >
            {title}
          </Text>
          <Flex overflowY="auto" full key={title}>
            {content}
          </Flex>
        </Flex>
      ))}
    </Flex>
  )
}
