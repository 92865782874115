import { useEffect, useCallback } from 'react'
import { useSetRecoilState } from 'recoil'
import { reCaptchaVerifiedAtom } from 'atoms'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

const VerifyReCaptcha = () => {
  const setReCaptchaVerified = useSetRecoilState(reCaptchaVerifiedAtom)
  const { executeRecaptcha } = useGoogleReCaptcha()

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) return

    const token = await executeRecaptcha('register')
    setReCaptchaVerified(token)
    // eslint-disable-next-line
  }, [executeRecaptcha])

  useEffect(() => {
    handleReCaptchaVerify()
  }, [handleReCaptchaVerify])
}

export default ({ children }) => {
  const reCaptchaKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY

  return (
    <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey}>
      <VerifyReCaptcha />
      {children}
    </GoogleReCaptchaProvider>
  )
}
