import { useRecoilState, useRecoilValue } from 'recoil'
import {
  commissionTotalAtom,
  emailAtom,
  paypalEmailAtom,
  useContactEmailAtom,
} from 'atoms'
import { Flex, Text, RichText, Input, Checkbox } from 'components'
import usePaymentComplete from './usePaymentComplete'
import { validate } from 'utils'

export default ({ text: { payment: { greeting, description } = {} } }) => {
  usePaymentComplete()

  const commissionTotal = useRecoilValue(commissionTotalAtom)
  const email = useRecoilValue(emailAtom)
  const [paypalEmail, setPaypalEmail] = useRecoilState(paypalEmailAtom)
  const [useContactEmail, setUseContactEmail] =
    useRecoilState(useContactEmailAtom)

  return (
    <Flex column align="center">
      <Flex marginBottom="30px" center column>
        <Text header margin="0">
          {greeting}
        </Text>
      </Flex>
      <Flex
        column
        width="500px"
        marginBottom="40px"
        center
        phoneFull
        phoneMargin="10px"
      >
        <Flex marginBottom="15px" phoneColumn>
          <Text emphasis size="20px" marginRight="10px">
            Your estimated total is:
          </Text>
          <Text emphasis size="20px" weight="700">
            ${commissionTotal.toFixed(2)}
          </Text>
        </Flex>
        <RichText>{description}</RichText>
      </Flex>
      <Flex column width="230px" phoneFull phoneMargin="10px">
        <Checkbox
          checked={useContactEmail}
          onClick={() => setUseContactEmail(!useContactEmail)}
          marginBottom="25px"
        >
          Use my contact email
        </Checkbox>
        <Input
          valid={validate.isEmail(paypalEmail) || useContactEmail}
          disabled={useContactEmail}
          label="Paypal email"
          value={[useContactEmail ? email : paypalEmail, setPaypalEmail]}
        />
      </Flex>
    </Flex>
  )
}
