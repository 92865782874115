import { Flex, Text, Link, Button, Image, Container } from 'components'
import { useSetRecoilState } from 'recoil'
import { stepAtom } from 'atoms'
import { truncate } from 'utils'

export default ({ hasReferences, uploads, links }) => {
  const setStep = useSetRecoilState(stepAtom)

  const linkText = (text) => {
    text = text.replace('http://', '')
    text = text.replace('https://', '')
    text = text.replace('www.', '')
    return truncate(text, 25)
  }

  return (
    <Container column marginBottom="25px" width="400px" phoneFull>
      <Text emphasis marginBottom="25px" phoneAlign="center">
        References
      </Text>
      {hasReferences ? (
        <Flex column gap="25px">
          {uploads?.map(({ id, url, description }) => (
            <Link href={url} key={id}>
              <Image full framed center height="200px" padding="25px" src={url}>
                {description && (
                  <Flex
                    column
                    center
                    background="white"
                    backgroundOpacity="25"
                    borderRadius="10px"
                    padding="10px 25px"
                    blur="32px"
                  >
                    <Text color="brand7" phoneSize="12px" phoneAlign="center">
                      {truncate(description, 20)}
                    </Text>
                  </Flex>
                )}
              </Image>
            </Link>
          ))}
          {links?.map(({ id, url, description }) => (
            <Link href={url} key={id}>
              <Container full center height="200px">
                <Flex
                  column
                  center
                  background="white"
                  backgroundOpacity="25"
                  borderRadius="10px"
                  padding="10px 25px"
                  blur="32px"
                  phoneMaxWidth="95%"
                >
                  <Text
                    marginRight="5px"
                    marginBottom="5px"
                    color="brand7"
                    size="16px"
                    weight="700"
                    wordBreak="break-all"
                    phoneSize="14px"
                    phoneAlign="center"
                  >
                    {linkText(url)}
                  </Text>
                  <Text color="brand7" phoneAlign="center" phoneSize="12px">
                    {truncate(description, 20)}
                  </Text>
                </Flex>
              </Container>
            </Link>
          ))}
        </Flex>
      ) : (
        <Text>No references selected</Text>
      )}
      <Flex center marginTop="25px">
        <Link
          href="/commission/customize/"
          onClick={() => setStep('References')}
        >
          <Button>Edit</Button>
        </Link>
      </Flex>
    </Container>
  )
}
