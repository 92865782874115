import { Text } from 'components'
import { documentToReactComponents as RichText } from '@contentful/rich-text-react-renderer'

export default ({ children, ...props }) =>
  children?.nodeType ? (
    <Text {...props}>
      <RichText {...children} />
    </Text>
  ) : (
    <></>
  )
