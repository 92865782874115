import { css } from 'styled-components'
import { colors as c } from 'utils'

export default () => css`
  body {
    margin: 0;
    padding: 0;
    background-color: ${c('background')};
    color: ${c('text')};
    font-size: 14px;
    font-weight: 500;
    max-width: 100%;
    overflow-x: hidden;
  }

  .grecaptcha-badge {
    visibility: hidden;
  }

  a:link {
    text-decoration: none;
  }

  a:hover {
    text-decoration: none;
  }

  ::-webkit-scrollbar {
    width: 15px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${c('brand1', '25')};
    border-right: 10px transparent solid;
    border-top: 10px transparent solid;
    border-bottom: 10px transparent solid;
    background-clip: padding-box;
  }

  * {
    box-sizing: border-box;
    font-family: 'Montserrat', 'Maison Neue Extended', sans-serif;
    &::selection {
      background: ${c('brand')};
      color: ${c('brand7')};
    }
  }
`
