import { Image } from 'components'

export default ({ src, ...props }) => (
  <Image
    src={src?.fields?.file?.url}
    width={`${src?.fields?.file?.details?.image?.width}px`}
    height={`${src?.fields?.file?.details?.image?.height}px`}
    {...props}
  />
)
