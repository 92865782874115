import { useResetRecoilState } from 'recoil'

import {
  agreedToTOSAtom,
  stepAtom,
  canProceedAtom,
  commissionTotalAtom,
  detailsAtom,
  detailsTabAtom,
  referencesAtom,
  uploadPreferenceAtom,
  referenceImageAtom,
  referenceLinkAtom,
  hasReferencesAtom,
  referenceDescriptionAtom,
  descriptionAtom,
  nameAtom,
  emailAtom,
  privateCommissionAtom,
  prefersCreditAtom,
  userPageLinkAtom,
  preferredCommunicationAtom,
  telegramUsernameAtom,
  discordUsernameAtom,
  paypalEmailAtom,
  useContactEmailAtom,
} from 'atoms'

export default () => {
  // customizer
  const resetAgreedToTOS = useResetRecoilState(agreedToTOSAtom)
  const resetStep = useResetRecoilState(stepAtom)
  const resetCanProceed = useResetRecoilState(canProceedAtom)
  const resetCommissionTotal = useResetRecoilState(commissionTotalAtom)

  // details
  const resetDetails = useResetRecoilState(detailsAtom)
  const resetDetailsTab = useResetRecoilState(detailsTabAtom)

  // references
  const resetReferences = useResetRecoilState(referencesAtom)
  const resetUploadPreference = useResetRecoilState(uploadPreferenceAtom)
  const resetReferenceImage = useResetRecoilState(referenceImageAtom)
  const resetReferenceLink = useResetRecoilState(referenceLinkAtom)
  const resetHasReferences = useResetRecoilState(hasReferencesAtom)
  const resetReferenceDescription = useResetRecoilState(
    referenceDescriptionAtom,
  )

  // description
  const resetDescription = useResetRecoilState(descriptionAtom)

  // contact
  const resetName = useResetRecoilState(nameAtom)
  const resetEmail = useResetRecoilState(emailAtom)
  const resetPrivateCommission = useResetRecoilState(privateCommissionAtom)
  const resetPrefersCredit = useResetRecoilState(prefersCreditAtom)
  const resetUserPageLink = useResetRecoilState(userPageLinkAtom)
  const resetPreferredCommunication = useResetRecoilState(
    preferredCommunicationAtom,
  )
  const resetTelegramUsername = useResetRecoilState(telegramUsernameAtom)
  const resetDiscordUsername = useResetRecoilState(discordUsernameAtom)

  // payment
  const resetPaypalEmail = useResetRecoilState(paypalEmailAtom)
  const resetUseContactEmail = useResetRecoilState(useContactEmailAtom)

  return () => {
    resetAgreedToTOS()
    resetStep()
    resetCanProceed()
    resetCommissionTotal()
    resetDetails()
    resetDetailsTab()
    resetReferences()
    resetUploadPreference()
    resetReferenceImage()
    resetReferenceLink()
    resetHasReferences()
    resetReferenceDescription()
    resetDescription()
    resetName()
    resetEmail()
    resetPrivateCommission()
    resetPrefersCredit()
    resetUserPageLink()
    resetPreferredCommunication()
    resetTelegramUsername()
    resetDiscordUsername()
    resetPaypalEmail()
    resetUseContactEmail()
  }
}
