import { useState, useEffect } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { commissionTotalAtom, detailsAtom } from 'atoms'
import { Flex, Text, ContentfulImage } from 'components'
import * as S from '../Cart.css'

export default ({ ...props }) => {
  const [detailsMap, setDetailsMap] = useState([])
  const [commissionTotal, setCommissionTotal] =
    useRecoilState(commissionTotalAtom)
  const details = useRecoilValue(detailsAtom)

  const makeDetailsMap = () => {
    const map = []
    Object.values(details).forEach((detail) => map.push(detail))
    const total = map.reduce((total, { price }) => total + price, 0)
    setCommissionTotal(total)
    setDetailsMap(map)
  }

  useEffect(() => {
    makeDetailsMap()
    // eslint-disable-next-line
  }, [details])

  return (
    <S.Cart
      full
      column
      justify="space-between"
      align="center"
      margin="40px 0"
      padding="25px"
      height="270px"
      {...props}
    >
      <Flex paddingBottom="25px">
        <Flex full justify="space-between">
          <Text
            emphasis
            color="brand7"
            size="20px"
            weight="600"
            marginRight="10px"
          >
            Total:
          </Text>
          <Text emphasis color="brand7" size="20px" weight="700">
            ${commissionTotal.toFixed(2)}
          </Text>
        </Flex>
      </Flex>
      <Flex tall full align="flex-start" gap="25px" overflowX="auto">
        {detailsMap?.map(({ type, thumbnail, price }) => (
          <ContentfulImage
            width="25%"
            framed
            center
            height="150px"
            minWidth="150px"
            minHeight="80px"
            background="white"
            backgroundOpacity="10"
            src={thumbnail}
            key={type}
          >
            <Text
              emphasis
              color="brand7"
              background="white"
              backgroundOpacity="25"
              blur="32px"
              padding="5px 10px"
              borderRadius="10px"
              margin="0 20px"
              size="10px"
              align="center"
            >
              {type}:{' '}
              <Text size="12px" weight="700" marginTop="5px">
                ${price.toFixed(2)}
              </Text>
            </Text>
          </ContentfulImage>
        ))}
      </Flex>
    </S.Cart>
  )
}
