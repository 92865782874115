import { MobileCart, TabletCart, DesktopCart } from './Views'
import { useWidth } from 'utils'

export default ({ ...props }) => {
  const width = useWidth()
  const Cart = determineCart(width)

  return <Cart />
}

const determineCart = (width) => {
  if (width < 768) return MobileCart
  if (width < 1230) return TabletCart
  return DesktopCart
}
