import { useState, useEffect } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { commissionTotalAtom, detailsAtom } from 'atoms'
import { Flex, Text, ContentfulImage } from 'components'
import * as S from '../Cart.css'

export default ({ ...props }) => {
  const [detailsMap, setDetailsMap] = useState([])
  const [commissionTotal, setCommissionTotal] =
    useRecoilState(commissionTotalAtom)
  const details = useRecoilValue(detailsAtom)

  const makeDetailsMap = () => {
    const map = []
    Object.values(details).forEach((detail) => map.push(detail))
    const total = map.reduce((total, { price }) => total + price, 0)
    setCommissionTotal(total)
    setDetailsMap(map)
  }

  useEffect(() => {
    makeDetailsMap()
    // eslint-disable-next-line
  }, [details])

  return (
    <S.Cart
      column
      justify="space-between"
      align="center"
      width="325px"
      margin="0 0 0 50px"
      {...props}
    >
      <Flex>
        <Text emphasis size="32px" color="brand7" padding="50px 0 20px 0">
          Cart
        </Text>
      </Flex>
      <Flex
        column
        tall
        full
        align="flex-start"
        padding="0 40px"
        overflowY="auto"
      >
        {detailsMap?.map(({ type, thumbnail, price }) => (
          <ContentfulImage
            full
            framed
            center
            height="150px"
            minHeight="80px"
            margin="10px 0"
            background="white"
            backgroundOpacity="10"
            src={thumbnail}
            key={type}
          >
            <Text
              emphasis
              color="brand7"
              background="white"
              backgroundOpacity="25"
              blur="32px"
              padding="5px 10px"
              borderRadius="10px"
              margin="0 20px"
              size="12px"
              align="center"
            >
              {type}:{' '}
              <Text size="16px" weight="700" marginTop="5px">
                ${price.toFixed(2)}
              </Text>
            </Text>
          </ContentfulImage>
        ))}
      </Flex>
      <Flex full padding="30px 40px 40px 40px" justify="space-between">
        <Text emphasis color="brand7" size="20px" weight="600">
          Total:
        </Text>
        <Text emphasis color="brand7" size="20px" weight="700">
          ${commissionTotal.toFixed(2)}
        </Text>
      </Flex>
    </S.Cart>
  )
}
