import { useState, useRef } from 'react'
import { Flex, Text, Icon, Container, Button, Textarea } from 'components'
import { truncate } from 'utils'
import { useOutsideClick } from 'utils'
import * as S from './UploadedDescription.css'

export default ({ description, references, setReferences, index }) => {
  const [editing, setEditing] = useState(false)
  const [updatedDescription, setUpdatedDescription] = useState(description)

  const editTextArea = useRef()
  useOutsideClick(editTextArea, () => setEditing(false))

  const updateText = () => {
    const _references = JSON.parse(JSON.stringify(references))
    _references[index].description = updatedDescription || ' '
    setReferences(_references)
    setEditing(false)
  }

  return (
    <Flex full position="relative" center>
      {editing && (
        <S.EditContainer ref={editTextArea}>
          <Container
            column
            full
            tall
            padding="10px"
            borderRadius="10px"
            background="darkGray"
            backgroundOpacity="99"
          >
            <Textarea
              full
              marginBottom="10px"
              height="100px"
              value={[updatedDescription, setUpdatedDescription]}
            />
            <Button full onClick={updateText}>
              Update
            </Button>
          </Container>
        </S.EditContainer>
      )}
      <S.ImageDescription
        full
        noSelect
        clickable
        blur="16px"
        padding="5px 15px"
        borderRadius="50px"
        background="brand1"
        backgroundOpacity="25"
        justify="space-between"
        align="center"
        height="30px"
        onClick={() => setEditing(true)}
      >
        <Text emphasis color="darkGray">
          {truncate(description, 10)}
        </Text>
        <Icon type="pencil" fill="darkGray" />
      </S.ImageDescription>
    </Flex>
  )
}
