import { css } from 'styled-components'
import { media } from 'styles'

export default ({
  width,
  minWidth,
  maxWidth,
  height,
  minHeight,
  maxHeight,
  full,
  tall,
}) => css`
  width: ${full ? '100%' : width};
  height: ${tall ? '100%' : height};
  min-width: ${minWidth};
  max-width: ${maxWidth};
  min-height: ${minHeight};
  max-height: ${maxHeight};

  ${tablet}
  ${phone}
`

const tablet = ({
  tabletWidth,
  tabletMinWidth,
  tabletMaxWidth,
  tabletHeight,
  tabletMinHeight,
  tabletMaxHeight,
  tabletFull,
  tabletTall,
}) => css`
  ${media.tablet`
    width: ${tabletFull ? '100%' : tabletWidth};
    height: ${tabletTall ? '100%' : tabletHeight};
    min-width: ${tabletMinWidth};
    max-width: ${tabletMaxWidth};
    min-height: ${tabletMinHeight};
    max-height: ${tabletMaxHeight};
  `}
`

const phone = ({
  phoneWidth,
  phoneMinWidth,
  phoneMaxWidth,
  phoneHeight,
  phoneMinHeight,
  phoneMaxHeight,
  phoneFull,
  phoneTall,
}) => css`
  ${media.phone`
    width: ${phoneFull ? '100%' : phoneWidth};
    height: ${phoneTall ? '100%' : phoneHeight};
    min-width: ${phoneMinWidth};
    max-width: ${phoneMaxWidth};
    min-height: ${phoneMinHeight};
    max-height: ${phoneMaxHeight};
  `}
`
