import {
  Flex,
  Link,
  BackgroundImage,
  ContentfulImage,
  Text,
  RichText,
  Loading,
} from 'components'
import { useContentful } from 'utils'

export default () => {
  const { background, tagline, description, galleries } =
    useContentful('portfolioPage')

  return (
    <Flex column phonePadding="10px">
      <BackgroundImage src={background} />
      <Flex justify="flex-start" padding="25px" phonePadding="0">
        <Link href="/">
          <Flex
            background="brand"
            width="40px"
            height="40px"
            borderRadius="50%"
          />
        </Link>
      </Flex>
      <Flex center column margin="50px 0 100px 0">
        <Text header marginBottom="25px">
          {tagline}
        </Text>
        <RichText width="350px" align="center" size="20px" phoneFull>
          {description}
        </RichText>
      </Flex>
      <Flex center full>
        <Flex
          display="flex-grid"
          justify="center"
          gap="50px"
          flexWrap="wrap"
          maxWidth="1000px"
          marginBottom="25px"
          phoneColumn
          phoneFull
          phoneGap="25px"
        >
          <Loading
            loading={!galleries}
            height="400px"
            width="50%"
            marginBottom="25px"
          >
            {galleries?.map(({ fields: { title, background } }) => (
              <Link href={`/portfolio/${title}`} key={title} phoneFull>
                <ContentfulImage
                  framed
                  src={background}
                  backgroundSize="cover"
                  height="400px"
                  width="300px"
                  center
                  phoneHeight="300px"
                  phoneMarginBottom="0"
                  phoneFull
                >
                  <Flex
                    padding="0 40px"
                    height="40px"
                    background="white"
                    backgroundOpacity="15"
                    blur="16px"
                    borderRadius="20px"
                    center
                  >
                    <Text emphasis>{title}</Text>
                  </Flex>
                </ContentfulImage>
              </Link>
            ))}
          </Loading>
        </Flex>
      </Flex>
    </Flex>
  )
}
