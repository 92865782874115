import styled from 'styled-components'
import {
  bezel,
  flexbox,
  sizes,
  spacing,
  positions,
  borders,
  decorations,
} from 'styles'

export const Modal = styled.div`
  ${bezel}
  ${flexbox}
  ${sizes}
  ${spacing}
  ${positions}
  ${borders}
  ${decorations}
`
