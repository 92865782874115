import styled, { css } from 'styled-components'
import Flex from 'components/Global/Flex/Flex'
import { colors as c } from 'utils'

export const Tab = styled(Flex)(
  ({ active }) => css`
    color: ${c(active ? 'brand' : 'brand1')};
    opacity: ${active ? '1' : '.25'};
    transition: 0.25s;

    &:last-child {
      padding-top: 7px;
    }

    &:first-child {
      padding-bottom: 7px;
    }

    &:not(:first-child):not(:last-child) {
      padding: 7px 0;
    }

    &:hover {
      opacity: ${active ? '1' : '0.5'};
    }
  `,
)
