import { useEffect } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { hasReferencesAtom, referencesAtom, canProceedAtom } from 'atoms'

export default () => {
  const hasReferences = useRecoilValue(hasReferencesAtom)
  const references = useRecoilValue(referencesAtom)
  const setCanProceed = useSetRecoilState(canProceedAtom)

  useEffect(() => {
    setCanProceed(!hasReferences || references.length)
    // eslint-disable-next-line
  }, [references, hasReferences])
}
