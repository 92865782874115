import styled from 'styled-components'
import {
  flexbox,
  sizes,
  spacing,
  positions,
  borders,
  decorations,
} from 'styles'

export const Flex = styled.div`
  ${flexbox}
  ${sizes}
  ${spacing}
  ${positions}
  ${borders}
  ${decorations}
`
