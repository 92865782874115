import { useState, useEffect } from 'react'
import {
  Flex,
  Button,
  SideTabs,
  Checkbox,
  Link,
  Text,
  RichText,
  Loading,
} from 'components'
import { useRecoilState } from 'recoil'
import { agreedToTOSAtom } from 'atoms'
import { useContentful } from 'utils'

export default () => {
  const { articles } = useContentful('termsOfService')
  const [terms, setTerms] = useState([])
  const [termsTab, setTermsTab] = useState('Will do')
  const [agreedToTOS, setAgreedToTOS] = useRecoilState(agreedToTOSAtom)

  const populateTerms = () => {
    const sanitizedTerms = Object.values(articles).map(
      ({ fields: { articleName, article } }) => ({
        title: articleName,
        content: (
          <Flex padding="0 15px" key={articleName}>
            <RichText tabletSize="12px">{article}</RichText>
          </Flex>
        ),
      }),
    )

    setTerms(sanitizedTerms)
  }

  useEffect(() => {
    articles && populateTerms()
    // eslint-disable-next-line
  }, [articles])

  return (
    <Flex column center minHeight="100vh" phonePadding="25px">
      <Text
        header
        size="24px"
        weight="700"
        marginBottom="25px"
        paddingLeft="10px"
        spacing="0"
        phoneSize="20px"
        phonePadding="0"
      >
        Terms of service
      </Text>
      <Loading
        loading={!articles}
        width="700px"
        height="300px"
        tabletWidth="400px"
        marginBottom="50px"
      >
        <SideTabs
          selected={[termsTab, setTermsTab]}
          tabs={terms}
          width="700px"
          height="300px"
          marginBottom="50px"
          tabletFull
          tabletPadding="50px"
          phoneFull
          phoneHeight="auto"
        />
      </Loading>
      <Checkbox
        checked={agreedToTOS}
        onClick={() => setAgreedToTOS(!agreedToTOS)}
        marginBottom="60px"
      >
        I agree
      </Checkbox>
      <Link href="/commission/customize" disabled={!agreedToTOS}>
        <Button glow>Continue</Button>
      </Link>
    </Flex>
  )
}
