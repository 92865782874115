import * as S from '../SideTabs.css'
import { Flex, Text } from 'components'

export default ({
  tabs,
  selected: [selected, setSelected],
  content,
  ...props
}) => {
  return (
    <Flex justify="flex-start" {...props}>
      <Flex column>
        {tabs?.map(({ title }) => (
          <S.Tab
            clickable
            justify="flex-end"
            active={title === selected}
            onClick={() => setSelected(title)}
            key={title}
          >
            <Text
              emphasis
              noSelect
              size="16px"
              weight="600"
              whiteSpace="nowrap"
            >
              {title}
            </Text>
          </S.Tab>
        ))}
      </Flex>
      <Flex full>
        {tabs?.map(
          ({ title, content }) =>
            title === selected && (
              <Flex overflowY="auto" full key={title}>
                {content}
              </Flex>
            ),
        )}
      </Flex>
    </Flex>
  )
}
