import * as S from './Checkbox.css'
import { Text, Icon } from 'components'

export default ({ checked, glow, children, ...props }) => (
  <S.Checkbox clickable center {...props}>
    <S.Box
      width="30px"
      height="30px"
      minWidth="30px"
      minHeight="30px"
      borderRadius="10px"
      phoneWidth="25px"
      phoneHeight="25px"
      phoneMinWidth="25px"
      phoneMinHeight="25px"
      phoneBorderRadius="7px"
      center
      glow={glow}
    >
      {checked && <Icon type="checkmark" fill="brand7" />}
    </S.Box>
    <Text margin="0 15px" emphasis noSelect>
      {children}
    </Text>
  </S.Checkbox>
)
