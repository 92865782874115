import { useRecoilValue } from 'recoil'

import {
  commissionTotalAtom,
  detailsAtom,
  referencesAtom,
  hasReferencesAtom,
  descriptionAtom,
  nameAtom,
  emailAtom,
  privateCommissionAtom,
  prefersCreditAtom,
  userPageLinkAtom,
  preferredCommunicationAtom,
  telegramUsernameAtom,
  discordUsernameAtom,
  paypalEmailAtom,
  useContactEmailAtom,
} from 'atoms'

export default () => {
  // customizer
  const commissionTotal = useRecoilValue(commissionTotalAtom)

  // details
  const details = useRecoilValue(detailsAtom)

  // references
  const references = useRecoilValue(referencesAtom)
  const hasReferences = useRecoilValue(hasReferencesAtom)

  // description
  const description = useRecoilValue(descriptionAtom)

  // contact
  const name = useRecoilValue(nameAtom)
  const email = useRecoilValue(emailAtom)
  const privateCommission = useRecoilValue(privateCommissionAtom)
  const prefersCredit = useRecoilValue(prefersCreditAtom)
  const userPageLink = useRecoilValue(userPageLinkAtom)
  const preferredCommunication = useRecoilValue(preferredCommunicationAtom)
  const telegramUsername = useRecoilValue(telegramUsernameAtom)
  const discordUsername = useRecoilValue(discordUsernameAtom)

  // payment
  const paypalEmail = useRecoilValue(paypalEmailAtom)
  const useContactEmail = useRecoilValue(useContactEmailAtom)

  const sanitizeDetails = () => {
    const _details = []

    Object.values(details).forEach(({ type, thumbnail, price }) =>
      _details.push({
        type: type,
        thumbnail: `http:${thumbnail?.fields?.file?.url}`,
        price: price,
      }),
    )

    return _details
  }

  const sanitizeReferences = () => {
    const uploads = []
    const links = []

    references.forEach(({ type, id, description, url }) => {
      const image = { id, description, url }

      type === 'image' && uploads.push(image)
      type === 'link' && links.push(image)
    })

    return { links, uploads }
  }

  const sanitizeContactPreference = () => {
    if (preferredCommunication === 'Email') return {}
    if (preferredCommunication === 'Telegram')
      return { username: `@${telegramUsername.replace('@', '')}` }
    if (preferredCommunication === 'Discord')
      return { username: discordUsername }
  }

  const sanitizeUserCredit = () => {
    if (prefersCredit) return { userPageLink }
    return {}
  }

  return {
    details: sanitizeDetails(),

    references: {
      hasReferences,
      ...sanitizeReferences(),
    },

    description: description,

    contact: {
      name: name,
      email: email,
      privateCommission,
      prefersCredit,
      ...sanitizeUserCredit(),
      preferredCommunication,
      ...sanitizeContactPreference(),
    },

    payment: {
      paypalEmail: useContactEmail ? email : paypalEmail,
      total: commissionTotal,
    },
  }
}
