import { useState } from 'react'
import { Image, OrbButton, Flex, Text } from 'components'
import { truncate } from 'utils'
import { s3Buckets } from 'api'
import UploadDescription from './UploadDescription/UploadDescription'

export default ({
  type,
  url,
  s3key,
  description,
  index,
  references: [references, setReferences],
}) => {
  const [deleting, setDeleting] = useState(false)

  const deleteReference = () => {
    setDeleting(true)
    const _references = [...references]
    _references.splice(index, 1)
    setReferences(_references)
  }

  const deleteFromS3 = () => {
    setDeleting(true)
    s3Buckets.delete(s3key).then((res) => {
      !res?.data?.error && deleteReference()
    })
  }

  const linkText = (text) => {
    text = text.replace('http://', '')
    text = text.replace('https://', '')
    text = text.replace('www.', '')
    return truncate(text, 25)
  }

  return (
    <Image
      framed
      height="220px"
      position="relative"
      align="flex-end"
      justify-content="space-between"
      padding="15px"
      opacity={deleting ? '.50' : '1'}
      transition=".1s"
      phoneHeight="210px"
      src={url}
    >
      <OrbButton
        type="exit"
        position="absolute"
        top="-10px"
        right="-10px"
        onClick={type === 'image' ? deleteFromS3 : deleteReference}
      />
      {type === 'link' && (
        <Flex
          center
          column
          top="25px"
          bottom="25px"
          right="0"
          left="0"
          position="absolute"
          padding="25px"
        >
          <Text emphasis size="16px" marginBottom="10px">
            Link
          </Text>
          <Text size="12px" wrap="break-word">
            {linkText(url)}
          </Text>
        </Flex>
      )}
      {description && (
        <UploadDescription
          {...{ description, references, setReferences, index }}
        />
      )}
    </Image>
  )
}
