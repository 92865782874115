import styled from 'styled-components'
import Image from 'components/Global/ContentfulImage/ContentfulImage'

export const BackgroundImage = styled(Image)`
  mask-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0) 50%
  );
`
