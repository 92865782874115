import { useState, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import { GridLoader } from 'react-spinners'
import { Image } from 'components'
import { useColors } from 'utils'
import * as S from './FileDropzone.css'

export default ({
  onDrop = () => {},
  text = "Drag n' drop files here",
  allowMultiple = false,
  fileTypes = [],
  error,
  background,
  loading,
  disabled,
  ...props
}) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })
  const c = useColors()

  const [color, setColor] = useState('brand')

  useEffect(() => {
    setColor(isDragActive ? 'brand1' : 'brand')
  }, [isDragActive])

  useEffect(() => {
    setColor(error ? 'error' : 'brand')
  }, [error])

  return (
    <S.FileDropzone
      full
      center
      clickable
      borderRadius="25px"
      border="5px dashed"
      transition=".25s"
      overflow="hidden"
      position="relative"
      disabled={loading || disabled}
      isDragActive={isDragActive}
      borderColor={color}
      {...getRootProps({ refKey: 'innerRef' })}
      {...props}
    >
      <S.HiddenInput
        {...getInputProps()}
        multiple={allowMultiple}
        accept={fileTypes?.join(', ')}
      />
      {loading ? (
        <GridLoader color={c(color)} />
      ) : (
        <S.UploadText
          emphasis
          color={color}
          width="180px"
          align="center"
          size="16px"
          transition=".25s"
          zIndex="1"
          opacity={background ? '0' : '1'}
        >
          {text}
        </S.UploadText>
      )}
      {background && <Image full tall position="absolute" src={background} />}
    </S.FileDropzone>
  )
}
