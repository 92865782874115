import { css } from 'styled-components'

export const sizes = {
  phone: [0, 440],
  tablet: [441, 768],
  laptop: [769, 1024],
  desktop: [1024, 1230],
  desktopPlus: [1231, 1380],
  monitor: [1381, 1500],
  monitorPlus: [1501, 2000],
}

export default Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...props) => css`
    @media screen and (min-width: ${sizes[label][0]}px) and (max-width: ${sizes[
        label
      ][1]}px) {
      ${css(...props)};
    }
  `
  return acc
}, {})
