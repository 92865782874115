import { useState } from 'react'
import { referenceImageAtom, referenceLoadingAtom } from 'atoms'
import { useRecoilState } from 'recoil'
import { useReCaptcha } from 'utils'
import { s3Buckets } from 'api'
import { FileDropzone } from 'components'

export default () => {
  const [referenceLoading, setReferenceLoading] =
    useRecoilState(referenceLoadingAtom)
  const [error, setError] = useState(false)
  const [text, setText] = useState("Drag n' drop images here")
  const [referenceImage, setReferencesImage] =
    useRecoilState(referenceImageAtom)

  const reCaptcha = useReCaptcha()

  const setDropZone = (text, error) => {
    setText(text)
    setReferenceLoading(false)
    setError(error)

    setTimeout(() => {
      setText("Drag n' drop images here")
      setError(false)
    }, 3000)
  }

  const uploadImage = async (files) => {
    const file = files[0]
    setReferenceLoading(true)

    const data = new FormData()
    const headers = {
      headers: {
        accept: 'application/json',
        'Accept-Language': 'en-US,en;q=0.8',
        'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
      },
    }

    if (file) {
      data.append('image', file, file.name)
      s3Buckets
        .upload(data, headers)
        .then(({ status, data }) => {
          if (status === 200) {
            if (data.error) {
              data.error.code === 'LIMIT_FILE_SIZE'
                ? setDropZone('File too large (5MB or less)', true)
                : setDropZone('Could not upload to server', true)
            } else {
              setReferencesImage(data)
              setDropZone('Upload Sucessful')
            }
          }
        })
        .catch((err) => setDropZone('File failed to upload', true))
    } else {
      setDropZone('Please upload an image file', true)
    }
  }

  return (
    <FileDropzone
      text={text}
      loading={referenceLoading}
      fileTypes={['.png', '.jpeg', '.jpg', '.gif']}
      onDrop={(files) => reCaptcha(() => uploadImage(files))}
      error={error}
      background={referenceImage?.url}
      margin="35px 0"
      height="250px"
      phoneHeight="230px"
    />
  )
}
