import {
  Modal,
  Flex,
  Text,
  RichText,
  Link,
  Button,
  ContentfulImage,
  OrbButton,
  Container,
} from 'components'

export default ({
  show: [show, setShow],
  image: { title, description, image, mirror },
  isFirst,
  isLast,
  previousImage,
  nextImage,
}) => (
  <Modal show={[show, setShow]}>
    <Flex justify="space-between" full phoneColumn>
      <Flex
        center
        full
        padding="25px"
        position="relative"
        phoneTall
        phonePadding="10px"
      >
        <Flex
          phonePosition="absolute"
          phoneLeft="10px"
          phoneBottom="10px"
          zIndex="1"
        >
          <OrbButton
            type="chevronLeft"
            marginRight="25px"
            disabled={isFirst}
            onClick={previousImage}
          />
        </Flex>
        <Link href={`http:${image?.fields?.file?.url}`} full tall>
          <ContentfulImage
            src={image}
            backgroundRepeat="no-repeat"
            backgroundSize="contain"
            backgroundPosition="center center"
            full
            tall
            maxWidth={`${image?.fields?.file?.details?.image?.width}px`}
            maxHeight={`${image?.fields?.file?.details?.image?.height}px`}
          />
        </Link>
        <Flex phonePosition="absolute" phoneRight="10px" phoneBottom="10px">
          <OrbButton
            type="chevronRight"
            marginLeft="25px"
            disabled={isLast}
            onClick={nextImage}
          />
        </Flex>
      </Flex>
      <Container
        column
        width="375px"
        minWidth="375px"
        borderRadius="0 25px 25px 0"
        justify="space-between"
        background="darkGray"
        backgroundOpacity="50"
        phoneFull
        phoneBorderRadius="0 0 25px 25px"
        phoneMaxHeight="180px"
        phoneOverflowY="auto"
        phoneTall
      >
        <Flex column phoneFull>
          <Text
            emphasis
            color="brand"
            size="20px"
            weight="600"
            marginRight="15px"
            marginBottom="25px"
          >
            {title}
          </Text>
          <RichText>{description}</RichText>
        </Flex>
        <Flex center>
          {mirror ? (
            <Link href={mirror}>
              <Button glow>View in FurAffinity</Button>
            </Link>
          ) : (
            <Flex />
          )}
        </Flex>
      </Container>
    </Flex>
  </Modal>
)
