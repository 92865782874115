import styled from 'styled-components'
import Flex from 'components/Global/Flex/Flex'
import { shine } from 'styles'
import { shinePressed } from 'styles/shine.css'
import { colors as c } from 'utils'

export const Box = styled(Flex)`
  ${shine}
`

export const Checkbox = styled(Flex)`
  &:active ${Box} {
    ${shinePressed}

    & path {
      fill: ${c('brand1')};
      stroke: ${c('brand1')};
    }
  }
`
