import axios from 'axios'

const { REACT_APP_SERVER } = process.env

export default {
  upload: (data, headers) =>
    axios.post(`${REACT_APP_SERVER}/api/s3upload`, data, headers),

  delete: (s3key) =>
    axios.delete(`${REACT_APP_SERVER}/api/s3delete?key=${s3key}`),
}
