import styled from 'styled-components'
import { bezel } from 'styles'
import { colors as c } from 'utils'

export const Input = styled.input`
  ${bezel}
  color: ${c('brand1')};
  background: ${c('white', '5')};
  height: 40px;
  padding: 10px;
  border-radius: 10px;
  border: none;
  outline: none;
  font-weight: 500;
  font-size: 14px;
  transition: 0.25s;

  &:focus {
    background: ${c('white', '10')};
  }
`
