import styled from 'styled-components'
import { sizes, spacing, positions, borders, decorations, shine } from 'styles'
import { colors as c } from 'utils'
import { media } from 'styles'

export const Button = styled.button`
  background: ${c('brand')};
  height: 40px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 3px;
  text-transform: uppercase;
  border: none;
  white-space: nowrap;
  user-select: none;

  ${media.phone`
    font-size: 12px;
    height: 35px;
  `}

  ${shine}
  ${sizes}
  ${spacing}
  ${positions}
  ${borders}
  ${decorations}
`
