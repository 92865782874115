import styled, { css } from 'styled-components'
import Flex from 'components/Global/Flex/Flex'
import { shine } from 'styles'
import { shinePressed } from 'styles/shine.css'
import { colors as c } from 'utils'

export const OrbTick = styled(Flex)(
  ({ selected }) => css`
    background: ${c('brand7')};
    width: ${selected ? `12px` : `0`};
    height: ${selected ? `12px` : `0`};
    border-radius: 50%;
    opacity: ${selected ? '1' : '0'};
    transition: 0.25s;
  `,
)

export const Orb = styled(Flex)`
  border-radius: 50%;
  ${shine}
`

export const RadioButton = styled(Flex)`
  &:active ${Orb} {
    ${shinePressed}

    & path {
      fill: ${c('brand1')};
      stroke: ${c('brand1')};
    }
  }

  &:active ${OrbTick} {
    background: ${c('brand1')};
    width: 12px;
    height: 12px;
    opacity: 1;
  }
`
