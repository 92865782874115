import { useRecoilState } from 'recoil'
import { descriptionAtom } from 'atoms'
import { Flex, Text, RichText, Textarea } from 'components'
import useDescriptionComplete from './useDescriptionComplete'

export default ({
  text: { description: { greeting, description: greetingDescription } = {} },
}) => {
  useDescriptionComplete()
  const [description, setDescription] = useRecoilState(descriptionAtom)

  return (
    <Flex column full>
      <Flex marginBottom="30px" center column>
        <Text header margin="0">
          {greeting}
        </Text>
        <RichText paddingTop="25px">{greetingDescription}</RichText>
      </Flex>
      <Textarea
        full
        tall
        phoneHeight="300px"
        maxLength="3000"
        valid={description}
        label={`${description.length}/3000 characters`}
        value={[description, setDescription]}
        placeholder="feel free to get detailed"
      />
    </Flex>
  )
}
