import { useEffect } from 'react'
import { colorsAtom, themeAtom } from 'atoms'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { themes } from 'constants'

export default () => {
  // declares the variables
  const { light, dark } = themes
  const setColors = useSetRecoilState(colorsAtom)
  const [theme, setTheme] = useRecoilState(themeAtom)

  // declares function to set both colors and theme
  const setColorAndTheme = (isDarkMode) => {
    setColors(isDarkMode ? dark : light)
    setTheme(isDarkMode ? 'dark' : 'light')
  }

  useEffect(() => {
    // sets the initial color scheme
    setColorAndTheme(true)

    // eslint-disable-next-line
  }, [theme])
}
