import React from 'react'
import * as icons from 'assets/icons'
import * as S from './Icon.css'

export default ({ type, fill, hoverfill, opacity, ...props }) => (
  <S.Icon
    src={icons?.[type]}
    fill={fill}
    hoverfill={hoverfill}
    opacity={opacity}
    {...props}
  />
)
