import { useEffect } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { commissionTotalAtom, detailsAtom } from 'atoms'
import { Flex, Text } from 'components'

export default () => {
  const [commissionTotal, setCommissionTotal] =
    useRecoilState(commissionTotalAtom)
  const details = useRecoilValue(detailsAtom)

  const makeDetailsMap = () => {
    const map = []
    Object.values(details).forEach((detail) => map.push(detail))
    const total = map.reduce((total, { price }) => total + price, 0)
    setCommissionTotal(total)
  }

  useEffect(() => {
    makeDetailsMap()
    // eslint-disable-next-line
  }, [details])

  return (
    <Flex center padding="25px" paddingTop="35px" full>
      <Text
        emphasis
        size="20px"
        weight="600"
        phoneSize="14px"
        tabletSize="14px"
        marginRight="10px"
      >
        Total:
      </Text>
      <Text
        emphasis
        size="20px"
        weight="700"
        phoneSize="14px"
        tabletSize="14px"
      >
        ${commissionTotal.toFixed(2)}
      </Text>
    </Flex>
  )
}
