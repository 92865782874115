import styled, { css } from 'styled-components'
import Flex from 'components/Global/Flex/Flex'
import { colors as c } from 'utils'
import * as images from 'assets/images'

export const Image = styled(Flex)(
  ({
    src,
    framed,
    backgroundColor,
    backgroundPosition = 'center center',
    backgroundSize = 'cover',
    backgroundRepeat = 'none',
    backgroundOrigin,
    backgroundClip,
    backgroundAttachment,
  }) => css`
    box-shadow: ${framed && css`inset 0px -1px 5px ${c('white', '25')}`};
    background-image: url(${images?.[src] || src});
    background-color: ${backgroundColor};
    background-position: ${backgroundPosition};
    background-size: ${backgroundSize};
    background-repeat: ${backgroundRepeat};
    background-origin: ${backgroundOrigin};
    background-clip: ${backgroundClip};
    background-attachment: ${backgroundAttachment};
  `,
)
