const baseColors = {
  white: '#ffffff',
  darkGray: '#262626',
  black: '#000000',
  brand: '#9eff00',
  brand1: '#efffd6',
  brand2: '#ceff7f',
  brand3: '#9eff00',
  brand4: '#7cc800',
  brand5: '#4a7800',
  brand6: '#284000',
  brand7: '#162300',
  error: '#eb8f4c',
}

export default {
  light: {
    isDarkMode: false,
    background: baseColors.brand1,
    text: baseColors.brand7,
    ...baseColors,
  },

  dark: {
    isDarkMode: true,
    background: baseColors.darkGray,
    text: baseColors.brand1,
    ...baseColors,
  },
}
