import { ThemeProvider } from 'styled-components'
import { colorsAtom } from 'atoms'
import { useRecoilValue } from 'recoil'
import isEmpty from 'lodash/isEmpty'
import { useSetDarkTheme } from 'utils'

export default ({ children }) => {
  useSetDarkTheme()
  const colors = useRecoilValue(colorsAtom)

  return (
    !isEmpty(colors) && <ThemeProvider theme={colors}>{children}</ThemeProvider>
  )
}
