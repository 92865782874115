import { css } from 'styled-components'
import { colors as c } from 'utils'

export const shinePressed = css`
  color: ${c('brand1')};
  background: radial-gradient(
    100% 100% at 50% 100%,
    ${c('brand5')} 0%,
    ${c('brand6')} 100%
  );
  box-shadow: ${({ glow }) => glowEffect(glow, 'brand5', '25')} inset 0px -3px 5px
    #00000040;
`

export default css`
  position: relative;
  overflow: hidden;

  background: radial-gradient(
    50% 50% at 50% 0%,
    ${c('brand2')} 0%,
    ${c('brand')} 100%
  );
  box-shadow: ${({ glow }) => glowEffect(glow, 'brand', '25')} inset 0px -3px 5px
    ${c('brand1', '60')};

  &:after {
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    position: absolute;
    background: white;
    user-select: none;
    transition: 0.1s;
  }

  &:hover {
    &:after {
      opacity: 0.1;
    }
  }

  &:active {
    &:after {
      opacity: 0;
    }
  }

  &:active {
    ${shinePressed}
  }
`
const glowEffect = (glow, color, opacity) =>
  glow ? css`0px 8px 15px ${c(color, opacity)}, ` : ''
