import {
  Flex,
  Link,
  Button,
  Text,
  RichText,
  Container,
  ContentfulImage,
  BackgroundImage,
  Loading,
} from 'components'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useContentfulQuery } from 'utils'
import ImageModal from './ImageModal/ImageModal'
import findIndex from 'lodash/findIndex'

export default () => {
  const { gallery } = useParams()
  const { background, images, tagline, title } = useContentfulQuery(
    'gallery',
    'fields.title',
    gallery,
  )

  const [selectedImage, setSelectedImage] = useState({})
  const [showImageModal, setShowImageModal] = useState(false)

  const viewImage = (image) => {
    setSelectedImage(image)
    setShowImageModal(true)
  }

  const currentImage = findIndex(images, (image) => {
    const title = image?.fields?.title
    const selectedTitle = selectedImage?.title

    return title === selectedTitle
  })

  const isFirst = currentImage === 0
  const isLast = currentImage === images?.length - 1

  const previousImage = () =>
    !isFirst && setSelectedImage(images?.[currentImage - 1]?.fields)

  const nextImage = () =>
    !isLast && setSelectedImage(images?.[currentImage + 1]?.fields)

  return (
    <>
      <BackgroundImage src={background} />
      <ImageModal
        image={selectedImage}
        show={[showImageModal, setShowImageModal]}
        isFirst={isFirst}
        isLast={isLast}
        previousImage={previousImage}
        nextImage={nextImage}
      />
      <Flex column align="center" phonePadding="10px">
        <Flex full justify="flex-start" padding="25px" phonePadding="0">
          <Link href="/">
            <Flex
              background="brand"
              width="40px"
              height="40px"
              marginRight="25px"
              borderRadius="50%"
            />
          </Link>
          <Link href="/portfolio">
            <Button phoneHeight="40px" phonePadding="0 40px">
              Back
            </Button>
          </Link>
        </Flex>
        <Flex center column margin="25px 0 100px 0">
          <Text header marginBottom="25px">
            {title}
          </Text>
          <RichText width="350px" align="center" size="20px" phoneFull>
            {tagline}
          </RichText>
        </Flex>
        <Container
          justify="flex-start"
          align="flex-start"
          display="flex-grid"
          flexWrap="wrap"
          gap="50px"
          maxWidth="1000px"
          blur="16px"
          phoneFull
          phoneColumn
          phoneGap="25px"
          phonePadding="10px"
        >
          <Loading loading={!images} height="340px">
            {images?.map(({ fields: image }) => (
              <Flex
                column
                center
                clickable
                phoneFull
                onClick={() => viewImage(image)}
                key={image.title}
              >
                <ContentfulImage
                  framed
                  width="200px"
                  height="300px"
                  marginBottom="15px"
                  phoneFull
                  src={image.thumbnail}
                />
                <Text size="14px" width="200px" align="center" phoneFull>
                  {image.title}
                </Text>
              </Flex>
            ))}
          </Loading>
        </Container>
      </Flex>
    </>
  )
}
