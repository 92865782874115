import { css } from 'styled-components'
import { hexOpacity } from 'constants'

export default (color, opacity) => {
  const transparentColorCSS = css`
    ${({ theme }) =>
      `${theme?.[color]}${hexOpacity?.[opacity] || 100}` || 'transparent'}
  `

  const colorCSS = css`
    ${({ theme }) => theme?.[color] || color}
  `

  return opacity ? transparentColorCSS : colorCSS
}
