import styled, { css } from 'styled-components'
import { propsToCSS } from 'utils'
import { text, sizes, spacing, positions, borders, decorations } from 'styles'
import { colors as c } from 'utils'
import { media } from 'styles'

const propStyles = {
  default: css`
    font-size: 14px;
    font-weight: 500;

    ${media.phone`
      font-size: 12px;
    `}
  `,

  header: css`
    color: ${c('brand')};
    font-weight: 600;
    font-size: 32px;
    letter-spacing: 5px;
    text-transform: uppercase;
    margin: inherit;

    ${media.phone`
      font-size: 24px;
      text-align: center;
    `}
  `,

  emphasis: css`
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 3px;
    text-transform: uppercase;

    ${media.phone`
      font-size: 12px;
    `}
  `,
}

export const Text = styled.div(
  ({ ...props }) => css`
    ${propsToCSS(props, propStyles)}

    & p {
      margin: 0;
      margin-bottom: 15px;
    }

    ${text}
    ${sizes}
    ${spacing}
    ${positions}
    ${borders}
    ${decorations}
  `,
)
