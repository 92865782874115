import styled from 'styled-components'
import { sizes, spacing, positions, borders, decorations, shine } from 'styles'
import { colors as c } from 'utils'

export const OrbButton = styled.button`
  ${shine}
  height: 30px;
  width: 30px;
  border-radius: 15px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;

  &:active {
    & path {
      fill: ${c('brand1')};
      stroke: ${c('brand1')};
    }
  }

  ${sizes}
  ${spacing}
  ${positions}
  ${borders}
  ${decorations}
`
