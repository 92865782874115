import { Flex, Text, Link, Button, Container } from 'components'
import { useSetRecoilState } from 'recoil'
import { stepAtom } from 'atoms'

export default ({ paypalEmail }) => {
  const setStep = useSetRecoilState(stepAtom)

  return (
    <Container column marginBottom="25px" width="400px" phoneFull>
      <Text emphasis marginBottom="25px" phoneAlign="center">
        Payment
      </Text>
      <Flex phoneColumn>
        <Text weight="600" marginRight="5px">
          • Paypal email for invoice:{' '}
        </Text>
        <Link href={`mailto:${paypalEmail}`}>
          <Text decoration="underline" wordBreak="break-all">
            {paypalEmail}
          </Text>
        </Link>
      </Flex>
      <Flex center marginTop="25px">
        <Link href="/commission/customize/" onClick={() => setStep('Payment')}>
          <Button>Edit</Button>
        </Link>
      </Flex>
    </Container>
  )
}
