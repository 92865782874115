import { css } from 'styled-components'
import { media } from 'styles'

export default ({
  font,
  size,
  weight,
  fontStyle,
  align,
  transform,
  decoration,
  line,
  spacing,
  whiteSpace,
  wordBreak,
}) => css`
  color: ${fontColor};
  font-family: ${font};
  font-size: ${size};
  font-weight: ${weight};
  font-style: ${fontStyle};
  text-align: ${align};
  text-transform: ${transform};
  text-decoration: ${decoration};
  line-height: ${line};
  letter-spacing: ${spacing};
  white-space: ${whiteSpace};
  word-break: ${wordBreak};

  ${tablet}
  ${phone}
`

const tablet = ({
  tabletSize,
  tabletWeight,
  tabletAlign,
  tabletLine,
  tabletSpacing,
}) => css`
  ${media.tablet`
    font-size: ${tabletSize};
    font-weight: ${tabletWeight};
    text-align: ${tabletAlign};
    line-height: ${tabletLine};
    letter-spacing: ${tabletSpacing};
  `}
`

const phone = ({
  phoneSize,
  phoneWeight,
  phoneAlign,
  phoneLine,
  phoneSpacing,
}) => css`
  ${media.phone`
    font-size: ${phoneSize};
    font-weight: ${phoneWeight};
    text-align: ${phoneAlign};
    line-height: ${phoneLine};
    letter-spacing: ${phoneSpacing};
  `}
`

const fontColor = css`
  ${({ theme, color }) => (color && theme?.[color]) || color}
`
