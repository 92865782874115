import { useRecoilValue } from 'recoil'
import { canProceedAtom } from 'atoms'
import { Flex, Link, Button } from 'components'
import { scrollToTop } from 'utils'
import findIndex from 'lodash/findIndex'

export default ({ step: [step, setStep] }) => {
  const stepOrder = [
    'Details',
    'References',
    'Description',
    'Contact',
    'Payment',
  ]

  const canProceed = useRecoilValue(canProceedAtom)
  const currentStep = findIndex(stepOrder, (e) => e === step)

  const onFirst = currentStep === 0
  const onLast = currentStep === stepOrder.length - 1

  const nextStep = () => {
    const nextIndex = currentStep + 1
    const next = stepOrder[nextIndex]
    scrollToTop()
    next && setStep(next)
  }

  const previousStep = () => {
    const previousIndex = currentStep - 1
    const previous = stepOrder[previousIndex]
    scrollToTop()
    previous && setStep(previous)
  }

  return (
    <Flex justify="space-between">
      <Link href={onFirst && '/commission'}>
        <Button glow onClick={previousStep}>
          Previous
        </Button>
      </Link>
      <Link href={onLast && '/commission/receipt'} disabled={!canProceed}>
        <Button glow disabled={!canProceed} onClick={nextStep}>
          Next
        </Button>
      </Link>
    </Flex>
  )
}
