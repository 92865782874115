import { atom } from 'recoil'

export const detailsAtom = atom({
  key: 'details',
  default: {},
})

export const detailsTabAtom = atom({
  key: 'detailsTab',
  default: 'Quality',
})
