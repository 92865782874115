import { css } from 'styled-components'
import { media } from 'styles'

export default ({
  padding,
  paddingTop,
  paddingRight,
  paddingBottom,
  paddingLeft,
  margin,
  marginTop,
  marginRight,
  marginBottom,
  marginLeft,
  gap,
}) => css`
  padding: ${padding};
  padding-top: ${paddingTop};
  padding-right: ${paddingRight};
  padding-bottom: ${paddingBottom};
  padding-left: ${paddingLeft};
  margin: ${margin};
  margin-top: ${marginTop};
  margin-right: ${marginRight};
  margin-bottom: ${marginBottom};
  margin-left: ${marginLeft};
  gap: ${gap};

  ${tablet}
  ${phone}
`

const tablet = ({
  tabletPadding,
  tabletPaddingTop,
  tabletPaddingRight,
  tabletPaddingBottom,
  tabletPaddingLeft,
  tabletMargin,
  tabletMarginTop,
  tabletMarginRight,
  tabletMarginBottom,
  tabletMarginLeft,
  tabletGap,
}) => css`
  ${media.tablet`
    padding: ${tabletPadding};
    padding-top: ${tabletPaddingTop};
    padding-right: ${tabletPaddingRight};
    padding-bottom: ${tabletPaddingBottom};
    padding-left: ${tabletPaddingLeft};
    margin: ${tabletMargin};
    margin-top: ${tabletMarginTop};
    margin-right: ${tabletMarginRight};
    margin-bottom: ${tabletMarginBottom};
    margin-left: ${tabletMarginLeft};
    gap: ${tabletGap};
  `}
`

const phone = ({
  phonePadding,
  phonePaddingTop,
  phonePaddingRight,
  phonePaddingBottom,
  phonePaddingLeft,
  phoneMargin,
  phoneMarginTop,
  phoneMarginRight,
  phoneMarginBottom,
  phoneMarginLeft,
  phoneGap,
}) => css`
  ${media.phone`
    padding: ${phonePadding};
    padding-top: ${phonePaddingTop};
    padding-right: ${phonePaddingRight};
    padding-bottom: ${phonePaddingBottom};
    padding-left: ${phonePaddingLeft};
    margin: ${phoneMargin};
    margin-top: ${phoneMarginTop};
    margin-right: ${phoneMarginRight};
    margin-bottom: ${phoneMarginBottom};
    margin-left: ${phoneMarginLeft};
    gap: ${phoneGap};
  `}
`
