import styled, { css } from 'styled-components'
import InlineSVG from 'react-inlinesvg'
import { colors as c } from 'utils'
import { spacing, decorations, positions } from 'styles'

export const Icon = styled(InlineSVG)(
  ({ fill, hoverfill, opacity }) => css`
    & path {
      fill: ${c(fill)};
      stroke: ${c(fill)};
      opacity: ${opacity};
      transition: fill stroke 0.25s;
    }

    &:hover {
      & path {
        fill: ${c(hoverfill)};
        stroke: ${c(hoverfill)};
      }
    }

    ${spacing}
    ${positions}
    ${decorations}
  `,
)
