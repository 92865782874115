import { useEffect } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { detailsAtom, canProceedAtom } from 'atoms'

export default (detailOptions) => {
  const details = useRecoilValue(detailsAtom)
  const setCanProceed = useSetRecoilState(canProceedAtom)

  useEffect(() => {
    if (details && detailOptions) {
      const totalOptions = detailOptions.map((_, i) => i)?.length
      const optionsSelected = Object.keys(details)?.length
      const hasSelectedAll = totalOptions === optionsSelected
      setCanProceed(hasSelectedAll)
    }
    // eslint-disable-next-line
  }, [details, detailOptions])
}
