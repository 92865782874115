import { useState, useEffect } from 'react'
import {
  Flex,
  Text,
  RichText,
  Button,
  Link,
  ContentfulImage,
  Loading,
} from 'components'
import { useContentful, randomNumber } from 'utils'

export default () => {
  const { message, critters } = useContentful('completePage')
  const [critter, setCritter] = useState({})

  const pickCritter = () => {
    const critterIndex = randomNumber(critters?.length) - 1
    const randomCritter = critters?.[critterIndex]?.fields
    setCritter(randomCritter)
  }

  useEffect(() => {
    critters && pickCritter()

    //eslint-disable-next-line
  }, [critters])

  return (
    <Flex center column minHeight="100vh">
      <Text header marginBottom="25px" size="24px" weight="700" spacing="0">
        All done!
      </Text>
      <Flex column align="center" phonePadding="10px">
        <Loading
          loading={!critters}
          height="480px"
          marginBottom="25px"
          width="420px"
        >
          <RichText align="center" marginBottom="25px" width="420px" phoneFull>
            {message}
          </RichText>
          <Flex width="420px" column center phoneFull>
            <Text emphasis marginBottom="10px">
              {critter?.critterName}
            </Text>
            <RichText align="center" size="12px">
              {critter?.text}
            </RichText>
            <ContentfulImage
              src={critter?.image}
              background="brand1"
              backgroundOpacity="10"
              width="300px"
              height="300px"
              borderRadius="10px"
              marginBottom="60px"
              phoneFull
            />
          </Flex>
        </Loading>
        <Link href="/">
          <Button glow>Continue</Button>
        </Link>
      </Flex>
    </Flex>
  )
}
