import styled from 'styled-components'
import Flex from 'components/Global/Flex/Flex'
import { colors as c } from 'utils'
import { media } from 'styles'

export const ImageDescription = styled(Flex)`
  background: ${c('brand1', '25')};
  transition: 0.25s;

  &:hover {
    background: ${c('brand1', '50')};
  }
`

export const EditContainer = styled.div`
  display: flex;
  width: 250px;
  justify-content: center;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  margin-bottom: 10px;
  backdrop-filter: blur(16px);

  ${media.phone`
    width: 210px;
  `}
`
