import { atom } from 'recoil'

export const themeAtom = atom({
  key: 'theme',
  default: 'light',
})

export const colorsAtom = atom({
  key: 'colors',
  default: {},
})
