import { Flex } from 'components'
import findIndex from 'lodash/findIndex'
import * as S from './ProgressDots.css'

export default ({ step, ...props }) => {
  const stepOrder = [
    'Details',
    'References',
    'Description',
    'Contact',
    'Payment',
  ]
  const currentStep = findIndex(stepOrder, (e) => e === step)

  return (
    <Flex center height="20px" marginBottom="35px" phoneMarginBottom="15px">
      {stepOrder?.map((step, i) => {
        const selected = i === currentStep

        return (
          <Flex
            background={selected ? 'brand' : 'brand1'}
            height={selected ? '15px' : '10px'}
            width={selected ? '15px' : '10px'}
            margin="0 5px"
            borderRadius="50%"
            transition=".25s"
            key={step}
          />
        )
      })}
      <S.ProgressDots {...props} />
    </Flex>
  )
}
