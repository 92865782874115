import styled, { css } from 'styled-components'
import Flex from 'components/Global/Flex/Flex'
import { shine, bezel } from 'styles'
import { colors as c } from 'utils'
import { media } from 'styles'

export const Tabs = styled(Flex)`
  ${bezel}
  box-shadow: inset 0px -1px 3px ${c('white', '15')};
  border-radius: 30px;
  padding: 5px;
`

export const Tab = styled(Flex)(
  ({ active }) => css`
    ${active && shine}
    color: ${c(active ? 'brand7' : 'brand1')};
    border-radius: 20px;
    height: 40px;
    padding: 0 40px;
    transition: all 0.25s, box-shadow 1ms;

    ${media.phone`
      padding: 0 20px;
    `}

    &:not(:last-child) {
      margin-right: 5px;
      ${media.phone`
        margin-right: 3px;
      `}
    }

    &:not(:first-child) {
      margin-left: 5px;
      ${media.phone`
        margin-right: 3px;
      `}
    }

    &:hover {
      background: ${!active && c('white', '5')};
    }
  `,
)
