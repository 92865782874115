import { useEffect } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { descriptionAtom, canProceedAtom } from 'atoms'

export default () => {
  const description = useRecoilValue(descriptionAtom)
  const setCanProceed = useSetRecoilState(canProceedAtom)

  useEffect(() => {
    setCanProceed(description.length)
    // eslint-disable-next-line
  }, [description])
}
