import { atom } from 'recoil'

export const agreedToTOSAtom = atom({
  key: 'agreedToTOSAtom',
  default: false,
})

export const stepAtom = atom({
  key: 'step',
  default: 'Details',
})

export const canProceedAtom = atom({
  key: 'canProceed',
  default: false,
})

export const commissionTotalAtom = atom({
  key: 'commissionTotal',
  default: 0,
})
