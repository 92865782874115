import { BrowserRouter, Routes, Route } from 'react-router-dom'
import {
  Home,
  Portfolio,
  Gallery,
  Commission,
  Playground,
  Customize,
  Receipt,
  Complete,
} from 'components'

export default () => (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/portfolio" element={<Portfolio />} />
      <Route path="/portfolio/:gallery" element={<Gallery />} />
      <Route path="/commission" element={<Commission />} />
      <Route path="/commission/customize" element={<Customize />} />
      <Route path="/commission/receipt" element={<Receipt />} />
      <Route path="/commission/complete" element={<Complete />} />
      <Route path="/Playground" element={<Playground />} />
    </Routes>
  </BrowserRouter>
)
