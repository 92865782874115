import { useRef } from 'react'
import { OrbButton } from 'components'
import { useOutsideClick } from 'utils'
import * as S from './Modal.css'

export default ({ children, show: [show, setShow], ...props }) => {
  const modal = useRef()
  useOutsideClick(modal, () => setShow(false))

  return (
    show && (
      <S.Modal
        position="fixed"
        blur="32px"
        zIndex="100"
        top="25px"
        right="25px"
        bottom="25px"
        left="25px"
        borderRadius="25px"
        background="darkGray"
        backgroundOpacity="50"
        phoneTop="5px"
        phoneRight="5px"
        phoneBottom="5px"
        phoneLeft="5px"
        ref={modal}
        {...props}
      >
        <OrbButton
          type="exit"
          position="absolute"
          top="15px"
          right="15px"
          zIndex="101"
          onClick={() => setShow(false)}
        />
        {children}
      </S.Modal>
    )
  )
}
