import styled from 'styled-components'
import { media } from 'styles'
import Container from 'components/Global/Container/Container'

export const ImagesContainer = styled(Container)`
  width: 400px;

  ${media.monitor`
    width: 400px;
  `}

  ${media.desktopPlus`
    width: 300px;
  `}

  ${media.desktop`
    width: 400px;
  `}

  ${media.laptop`
    width: 200px;
  `}
`
