import * as S from './BackgroundImage.css'

export default ({ ...props }) => (
  <S.BackgroundImage
    full
    tall
    position="absolute"
    top="0"
    left="0"
    zIndex="-1"
    {...props}
  />
)
