import { atom } from 'recoil'

export const nameAtom = atom({
  key: 'name',
  default: '',
})

export const emailAtom = atom({
  key: 'email',
  default: '',
})

export const privateCommissionAtom = atom({
  key: 'privateCommission',
  default: false,
})

export const prefersCreditAtom = atom({
  key: 'prefersCredit',
  default: true,
})

export const userPageLinkAtom = atom({
  key: 'userPageLink',
  default: '',
})

export const preferredCommunicationAtom = atom({
  key: 'preferredCommunication',
  default: 'Telegram',
})

export const telegramUsernameAtom = atom({
  key: 'telegramUsername',
  default: '',
})

export const discordUsernameAtom = atom({
  key: 'discordUsername',
  default: '',
})
