import { useState, useEffect } from 'react'
import { useRecoilState } from 'recoil'
import { stepAtom } from 'atoms'
import { Flex } from 'components'
import { useContentful } from 'utils'
import { ProgressDots, StepNavigation, Cart } from '.'
import * as steps from './Steps'
import * as S from './Cutsomize.css'

export default () => {
  const { customizerSteps } = useContentful('customizerText')
  const [text, setText] = useState({})
  const [step, setStep] = useRecoilState(stepAtom)
  const Step = steps?.[step] || steps.Details

  const populateText = () => {
    const sanitizedSteps = {}
    customizerSteps.forEach(
      ({ fields: { page, greetingText, description } }) => {
        sanitizedSteps[page] = { greeting: greetingText, description }
      },
    )

    setText(sanitizedSteps)
  }

  useEffect(() => {
    customizerSteps && populateText()
    // eslint-disable-next-line
  }, [customizerSteps])

  return (
    <S.Container
      padding="50px"
      height="100vh"
      phonePadding="10px"
      phoneColumn
      phoneHeight="auto"
      tabletColumn
    >
      <Flex full tall justify="space-between" column>
        <ProgressDots step={step} />
        <Flex
          tall
          justify="center"
          overflowY="auto"
          phoneOverflowY="visible"
          marginBottom="40px"
          phoneMarginBottom="15px"
        >
          <Step step={[step, setStep]} text={text} />
        </Flex>
        <StepNavigation step={[step, setStep]} />
      </Flex>
      <Flex tall>
        <Cart />
      </Flex>
    </S.Container>
  )
}
