import { useEffect, useState } from 'react'

import { createClient } from 'contentful'
const { REACT_APP_CONTENTFUL_SPACE_ID, REACT_APP_CONTENTFUL_ACCESS_TOKEN } =
  process.env

export default (contentType, search, value) => {
  const [data, setData] = useState({})

  const client = createClient({
    space: REACT_APP_CONTENTFUL_SPACE_ID,
    accessToken: REACT_APP_CONTENTFUL_ACCESS_TOKEN,
  })

  const getContent = async () => {
    try {
      const response = await client.getEntries({
        content_type: contentType,
        select: 'fields',
        limit: 1000,
        [search]: value,
      })

      const sanitizedResponse = response.items?.[0]?.fields
      setData(sanitizedResponse)
    } catch {
      setData({})
    }
  }

  useEffect(() => {
    getContent()
    // eslint-disable-next-line
  }, [])

  return data
}
