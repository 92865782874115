import styled, { css } from 'styled-components'
import { Link as ReactLink } from 'react-router-dom'
import { disabled, positions, spacing, sizes } from 'styles'

const anchorTag = ({ href, to }) => css`
  display: inherit;
  color: inherit;
  cursor: ${href || to ? 'pointer' : 'default'};
  ${sizes}
  ${spacing}
  ${positions}
  ${disabled}
`

export const InternalLink = styled(ReactLink)`
  ${anchorTag}
`

export const ExternalLink = styled.a`
  ${anchorTag}
`

export const ProtocolLink = styled.a`
  ${anchorTag}
`
