import { useState } from 'react'
import {
  Flex,
  Text,
  Button,
  OrbButton,
  Checkbox,
  RadioButtons,
  Input,
  Textarea,
  Tabs,
  Container,
  SideTabs,
  Image,
} from 'components'

export default () => {
  const [checked, setChecked] = useState(false)
  const [radioButton, setRadioButton] = useState('one')
  const [textbox, setTextbox] = useState('')
  const [textarea, setTextarea] = useState('')
  const [activeTab, setActiveTab] = useState('Tab 1')
  const [activeSideTab, setActiveSideTab] = useState('quality')

  return (
    <Flex column center minHeight="100vh">
      <Text header marginBottom="15px">
        Header text
      </Text>
      <Text emphasis color="brand2" marginBottom="15px">
        Emphasis text
      </Text>
      <Text marginBottom="25px">General text</Text>

      <Button glow marginBottom="25px">
        Button
      </Button>

      <Flex marginBottom="25px">
        <OrbButton type="exit" marginRight="10px" />
        <OrbButton type="chevronLeft" marginRight="10px" />
        <OrbButton type="chevronRight" marginRight="10px" />
      </Flex>

      <Checkbox
        glow
        onClick={() => setChecked(!checked)}
        checked={checked}
        marginBottom="25px"
      >
        Checkbox
      </Checkbox>

      <RadioButtons
        glow
        selected={[radioButton, setRadioButton]}
        options={[
          { text: 'Sketch', value: 'one' },
          { text: 'Inking', value: 'two' },
          { text: 'Colored', value: 'three' },
          { text: 'Shaded', value: 'four' },
        ]}
        marginBottom="25px"
      />

      <Input
        label="Textbox"
        value={[textbox, setTextbox]}
        marginBottom="25px"
      />

      <Textarea
        label="Textarea"
        value={[textarea, setTextarea]}
        width="400px"
        height="300px"
        marginBottom="25px"
      />

      <Tabs
        selected={[activeTab, setActiveTab]}
        tabs={[
          { title: 'Tab 1', content: <Flex margin="15px">Tab 1</Flex> },
          { title: 'Tab 2', content: <Flex margin="15px">Tab 2</Flex> },
          { title: 'Tab 3', content: <Flex margin="15px">Tab 3</Flex> },
        ]}
      />

      <Container marginBottom="25px">
        <Text emphasis>Container</Text>
      </Container>

      <SideTabs
        selected={[activeSideTab, setActiveSideTab]}
        tabs={[
          {
            title: 'quality',
            content: <Flex margin="0 15px">Tab 1</Flex>,
          },
          {
            title: 'background',
            content: <Flex margin="0 15px">Tab 2</Flex>,
          },
          { title: 'chracters', content: <Flex margin="0 15px">Tab 3</Flex> },
          { title: 'frame', content: <Flex margin="0 15px">Tab 4</Flex> },
        ]}
        width="500px"
        marginBottom="25px"
      />

      <Image
        framed
        src="ferns"
        backgroundSize="cover"
        height="400px"
        width="300px"
        marginBottom="25px"
        center
      >
        <Flex
          padding="0 40px"
          height="40px"
          background="white"
          backgroundOpacity="15"
          blur="16px"
          borderRadius="20px"
          center
        >
          <Text emphasis>2022</Text>
        </Flex>
      </Image>
    </Flex>
  )
}
