import { useEffect } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { paypalEmailAtom, useContactEmailAtom, canProceedAtom } from 'atoms'
import { validate } from 'utils'

export default () => {
  const paypalEmail = useRecoilValue(paypalEmailAtom)
  const useContactEmail = useRecoilValue(useContactEmailAtom)
  const setCanProceed = useSetRecoilState(canProceedAtom)

  const validPaypalEmail = validate.isEmail(paypalEmail) || useContactEmail
  const filledOutEmail = useContactEmail ? true : paypalEmail

  const canProceed = validPaypalEmail && filledOutEmail

  useEffect(() => {
    setCanProceed(canProceed)
    // eslint-disable-next-line
  }, [paypalEmail, useContactEmail])
}
