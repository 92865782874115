import { useEffect } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import {
  nameAtom,
  emailAtom,
  prefersCreditAtom,
  userPageLinkAtom,
  preferredCommunicationAtom,
  telegramUsernameAtom,
  discordUsernameAtom,
  canProceedAtom,
} from 'atoms'
import { validate } from 'utils'

export default () => {
  const name = useRecoilValue(nameAtom)
  const email = useRecoilValue(emailAtom)
  const prefersCredit = useRecoilValue(prefersCreditAtom)
  const userPageLink = useRecoilValue(userPageLinkAtom)
  const preferredCommunication = useRecoilValue(preferredCommunicationAtom)
  const telegramUsername = useRecoilValue(telegramUsernameAtom)
  const discordUsername = useRecoilValue(discordUsernameAtom)
  const setCanProceed = useSetRecoilState(canProceedAtom)

  const validEmail = validate.isEmail(email)
  const validUserPageLink = prefersCredit ? validate.isUrl(userPageLink) : true
  const validDiscordUsername =
    preferredCommunication === 'Discord'
      ? validate.isDiscordUsername(discordUsername)
      : true

  const formsFilled = name && email
  const wantsCredit = prefersCredit ? userPageLink : true
  const preferenceSelected = determinePreferences(
    preferredCommunication,
    telegramUsername,
    discordUsername,
  )

  const canPass =
    formsFilled &&
    wantsCredit &&
    preferenceSelected &&
    validEmail &&
    validUserPageLink &&
    validDiscordUsername

  useEffect(() => {
    setCanProceed(canPass)
    // eslint-disable-next-line
  }, [
    name,
    email,
    userPageLink,
    prefersCredit,
    preferredCommunication,
    telegramUsername,
    discordUsername,
    validEmail,
    validUserPageLink,
    validDiscordUsername,
  ])
}

const determinePreferences = (selected, telegram, discord) => {
  if (selected === 'Email') return true
  if (selected === 'Telegram') return telegram
  if (selected === 'Discord') return discord
}
